import { DDSTooltip, DDSTypography } from 'den-design-system'
import React, { useEffect, useRef, useState } from 'react'
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import { gaEventConstants, gaPageConstants } from '../../constants/GAConstants'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../utils/UrlUtils'
import { isArray, isEmpty, isNull } from 'lodash'
import { msToTimeUnits, timeZone } from '../../utils/DateTimeUtils'

import ApiGroupingStringConstants from '../../constants/ApiGroupingStringConstants'
import { ChevronRight } from '@carbon/icons-react'
import { Link } from '@mui/material'
import NoDataImage from '../Overview/NoDataImage'
import NoRecord from '../common/NoContent/NoRecord'
import NoRecordImage from '../../assets/images/setting.svg'
import StatusCodeGraph from '../common/StatusCodeGraph/StatusCodeGraph'
import StringConstants from '../../constants/StringConstants'
import Table from '../common/DDS/Table/Table'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../common/DDS/Toast/Toast'
import { color } from '../../utils/ApiStatusColorCode'
import { fetchGroupedApiRecords } from '../../api/apiMonitoring/ApiMonitoring.service'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'
import routeConstants from '../../constants/RouteConstants'
import { timeFormats } from '../../enum/TIME'
import { useAnalyticsEventTracker } from '../../utils/GAUtils'
import { useStoreContext } from '../../store/StoreContext'

const GroupedAPI: React.FC = () => {
  const [groupedApiTableData, setGroupedApiTableData] = useState<any>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState<boolean>()

  const navigate = useNavigate()
  const store = useStoreContext()
  const location = useLocation()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.API_MONITORING_TABLE
  )
  const tableRef = useRef<HTMLDivElement>(null)
  const [tableHeight, setTableHeight] = useState<number | null>(null)

  useEffect(() => {
    if (tableRef.current) {
      setTableHeight(tableRef.current.clientHeight)
    }
  }, [groupedApiTableData, loading])

  const routeGroupedDetails = (id: number) => {
    const route = replaceUrl(
      routeConstants.VIEW_GROUPED_API,
      store.scopeStore.getScope()['organization-id'] as string,
      store.scopeStore.getScope()['project-id'] as string,
      store.scopeStore.getScope()['environment-id'] as string
    ).replace(':id', String(id))

    const queryParams = { tab: '1' }
    navigate({
      pathname: route,
      search: `?${createSearchParams(queryParams)}`
    })
  }

  const groupedApiData = (queryParams: any) => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    fetchGroupedApiRecords(queryParams)
      .then((response: any) => {
        setGroupedApiTableData(response.data)
        store.apiDetailsStore.setRefresh(false)
        setTotalCount(response.totalCount)
        setLoading(false)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')
    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))
    } else {
      setCurrentPage(1)
    }
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    groupedApiData({
      ...allQueryParams,
      size: StringConstants.DEFAULT_TABLE_SIZE
    })
  }, [
    store.uiStore.setGlobalLoader(false),
    location.search,
    store.scopeStore.getSelectedProject(),
    store.scopeStore.getSelectedEnvironment()
  ])

  useEffect(() => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (store.apiDetailsStore.getRefresh() === true) {
      groupedApiData({
        ...allQueryParams,
        size: StringConstants.DEFAULT_TABLE_SIZE
      })
    }
  }, [store.apiDetailsStore.getRefresh()])

  const Columns = [
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.URL_TEMPLATE}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'urlTemplate',
      render: (text: any) => (
        <DDSTooltip
          id={`${text}`}
          label={
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark4}
            >
              {text}
            </DDSTypography.Paragraph>
          }
          shape='roundedRectangle'
          position='top'
          className={`override-tooltip-arrow ${
            typeof text === 'string' && text.length > 20 ? text : '!hidden'
          }`}
          style={{ backgroundColor: pallete.colors.surface1, zIndex: 999999 }}
        >
          <div className='flex justify-start text-start row items-start w-[120px]'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate w-[120px]'
            >
              {text || text === 0 ? text : '-'}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.HTTP_METHOD}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'httpMethod',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.API_COUNT}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'apiCount',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.AVERAGE_RESPONSE_TIME}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'averageResponseTimeInMilliSeconds',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
            className='tooltip-container sm:block overflow-hidden'
          >
            {text ? msToTimeUnits(text) : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: { width: '120px' },
      heading: (
        <div className='flex row justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.LAST_CALL_DATE}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'lastCallDate',
      render: (text: any) => (
        <DDSTooltip
          id={`${text}`}
          label={
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark4}
            >
              {timeZone(
                store.userStore.getUserPreference().timeZone,
                text,
                timeFormats.fullMonthDateYearTimeWithSeconds
              )}
            </DDSTypography.Paragraph>
          }
          shape='roundedRectangle'
          position='top'
          className={`override-tooltip-arrow ${
            typeof text === 'string' && text.length > 20 ? text : '!hidden'
          }`}
          style={{
            backgroundColor: pallete.colors.surface1,
            zIndex: 999999
          }}
        >
          <div className='flex justify-center text-center row items-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate'
            >
              {timeZone(
                store.userStore.getUserPreference().timeZone,
                text,
                timeFormats.fullMonthDateYearTimeWithSeconds
              )}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    {
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.STATUS_CODE_GRAPH}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'statusCodeGraph',
      render: (text: any, fullItem: any) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <StatusCodeGraph
            width='144px'
            tableHeight={tableHeight}
            totalCount={totalCount}
            data={fullItem['statusCodeGraph']}
            color={color}
          />
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '20px' },
      columnDataStyle: { width: '20px' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='cursor-pointer'>
          <ChevronRight
            size={16}
            onClick={() => {
              gaEventTracker(gaEventConstants.VIEW_SINGLE_API_RECORD)
              routeGroupedDetails(fullItem.id)
            }}
            color={pallete.colors.FillButton}
          />
        </div>
      )
    }
  ]

  return (
    <>
      {loading ||
      (isArray(groupedApiTableData) && groupedApiTableData.length !== 0) ? (
        <div className='py-[24px]' ref={tableRef}>
          <Table
            tableStyle={{
              borderColor: pallete.colors.stroke2
            }}
            data={groupedApiTableData}
            columns={Columns}
            totalCount={totalCount}
            rowsPerPage={10}
            currentPage={currentPage}
            onPageChange={(page: number) => {
              const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                location.search
              )
              setSearchParams({ ...allQueryParams, page: page.toString() })
              setCurrentPage(page)
            }}
            loading={loading}
          />
        </div>
      ) : (
        <>
          <NoRecord
            imageSrc={NoRecordImage}
            style={{ height: '80vh' }}
            text={
              store.filterStore.getFiltersApplied() ? (
                <>No results found for applied filter.</>
              ) : (
                <>
                  Configure API Monitoring.
                  <Link
                    href={StringConstants.FULLSTACK_URL}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ marginLeft: '4px' }}
                  >
                    Learn More
                  </Link>
                </>
              )
            }
          />
        </>
      )}
    </>
  )
}

export default observer(GroupedAPI)
