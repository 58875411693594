import { AtoB, UrlWithOrgProjEnvIds, replaceUrl } from '../../../utils/UrlUtils'
import { Notification, Template, UserFollow } from '@carbon/icons-react'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { DDSTypography } from 'den-design-system'
import { EventBus } from '../../../eventBus/EventBus'
import HttpConstants from '../../../constants/HttpConstants'
import { OrganizationType } from '../../../enum/OrganizationType.enum'
import { OrganizationUserRoles } from '../../../enum/OrganizationUserRoles.enum'
import StringConstants from '../../../constants/StringConstants'
import Tour from 'reactour'
import { addQueryParamsToUrl } from '../../../utils/UrlGenerator'
import { gaPageConstants } from '../../../constants/GAConstants'
import { getOrganizationProjectsThingsToDo } from '../../../api/organization/Organization.service'
import { getSideBarTabs } from '../../../utils/SidebarUtils'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

interface SidebarItem {
  id: number
  title: string
  leftIcon: JSX.Element
  link: string
  topIcon: any
  tourId: any
}

interface SidebarProps {
  isSidebarExpanded: boolean
  palette: any
  organizations: any[]
  projectType: any
}

export interface SidebarRef {
  handleSidebarItemClick: (item: SidebarItem) => void
}

const SidebarLayout = forwardRef<SidebarRef, SidebarProps>((props, ref) => {
  const { isSidebarExpanded, palette, organizations, projectType } = props

  const navigate = useNavigate()
  const store = useStoreContext()

  const [searchParams, setSearchParams] = useSearchParams()
  const gaEventTracker = useAnalyticsEventTracker(gaPageConstants.SIDE_NAVBAR)
  const [sidebarItems, setSidebarItems] = useState<SidebarItem[]>([])
  const [selectedOption, setselectedOption] = React.useState<any>(-1)
  const handleButtonClick = (buttonId: any) => {
    setselectedOption(buttonId)
  }

  const NotificationSidebarTabs = [
    {
      title: 'Notifications',
      link: routeConstants.NOTIFICATIONS,
      leftIcon: <Notification />,
      topIcon: <Notification size={24} color={pallete.colors.primary2} />
    },
    {
      title: 'Invitations',
      leftIcon: <UserFollow />,
      link: routeConstants.INVITES,
      topIcon: <UserFollow size={24} color={pallete.colors.primary2} />
    }
  ]

  useImperativeHandle(ref, () => ({
    handleSidebarItemClick
  }))

  useEffect(() => {
    if (!localStorage.getItem(StringConstants.ACTIVE_TAB))
      localStorage.setItem(
        StringConstants.ACTIVE_TAB,
        StringConstants.TAB_VALUES.OVERVIEW
      )
  }, [])

  useEffect(() => {
    if (!localStorage.getItem(StringConstants.ACTIVE_TAB))
      localStorage.setItem(
        StringConstants.ACTIVE_TAB,
        StringConstants.TAB_VALUES.OVERVIEW
      )
  }, [])

  useEffect(() => {
    const fetchSidebarItems = async () => {
      const userRole = store.userStore.getUserRole()
      const tabs = await getSideBarTabs(
        userRole,
        store.scopeStore.getSelectedProject().type
      )
      setSidebarItems(tabs)
    }
    fetchSidebarItems()
  }, [store.userStore, projectType, store.scopeStore.getSelectedProject()])

  useEffect(() => {
    const organizationId = store.scopeStore.getScope()['organization-id']
    const organization = organizations.filter(
      (record: any) => record.id === organizationId
    )
    if (organization.length > 0) {
      store.userStore.setSelectedOrganization(organization[0])
      if (!organization[0].subscription) {
        store.userStore.setNoActiveSubscription(true)
      } else {
        store.userStore.setNoActiveSubscription(false)
      }
    }
    if (
      !window.location.pathname.includes('preferences') &&
      !window.location.pathname.includes('organization')
    ) {
      store.uiStore.setGlobalLoader(true)
    } else {
      store.uiStore.setGlobalLoader(false)
    }
  }, [store.scopeStore.getOrganizationId()])

  React.useEffect(() => {
    if (
      window.location.pathname !== routeConstants.INVITES &&
      window.location.pathname !== routeConstants.NOTIFICATIONS
    ) {
      TabItemHandler()
    } else {
      NotificationTabItemHandler()
    }
  }, [
    window.location.pathname,
    store.scopeStore.getSelectedProject(),
    searchParams
  ])

  const TabItemHandler = () => {
    store.uiStore.setNotificationSideNavbar(false)

    getSideBarTabs(
      store.userStore.getUserRole(),
      store.scopeStore.getSelectedProject().type
    ).map((item: any) => {
      const pathname = window.location.pathname.split('/')
      if (item) {
        if (
          (pathname[1] === 'settings' && item.title === 'Settings') ||
          pathname.includes(item.link.split('/')[1])
        ) {
          setselectedOption(item.id)
          store.uiStore.setSidebarItem(item)
        }
      }
    })
  }

  const NotificationTabItemHandler = () => {
    store.uiStore.setNotificationSideNavbar(true)
    NotificationSidebarTabs.map((item) => {
      const params = new URLSearchParams(window.location.search)
      const innerTab = params.get('innerTab')
      if (
        (item.link === '/allNotifications' && item.title === 'Notifications') ||
        (item.link === '/allInvites' &&
          innerTab === '2' &&
          item.title === 'Invitations')
      ) {
        store.uiStore.setSidebarItem(item)
        setselectedOption(item.link)
      }
    })
  }

  const handleSidebarItemClick = (item: SidebarItem) => {
    store.filterStore.setFiltersApplied(false)
    store.breadcrumbStore.reset()
    if (store.scopeStore.getProjectId()?.length) {
      handleButtonClick(item.id)

      const organizationId = store.scopeStore.getScope()[
        'organization-id'
      ] as string
      const projectId = store.scopeStore.getScope()['project-id'] as string
      const environmentId = store.scopeStore.getScope()[
        'environment-id'
      ] as string
      if (item.id === selectedOption) {
        const currentUrl = window.location.pathname

        const existingUrl = replaceUrl(
          item.link,
          organizationId,
          projectId,
          environmentId
        )
        if (currentUrl !== existingUrl) {
          navigate(existingUrl)
        } else {
          return
        }
      }
      gaEventTracker(item.title + ' Tab')
      setSearchParams({})

      if (item.title !== 'Settings') {
        if (!isEmpty(store.scopeStore.getScope()['project-id'])) {
          navigate(
            '../' +
              replaceUrl(item.link, organizationId, projectId, environmentId)
          )
        } else {
          navigate('../' + replaceUrl(item.link, organizationId, '', ''))
        }
      } else {
        navigate(
          replaceUrl(item.link, organizationId, projectId, environmentId)
        )
      }
      TabItemHandler()
    } else {
      navigate(
        addQueryParamsToUrl(
          routeConstants.REDIRECT,
          {
            redirect: UrlWithOrgProjEnvIds(item.link, store)
          },

          HttpConstants.GET_METHOD
        )
      )
    }
  }

  return (
    <div id='sidebar-item'>
      {sidebarItems.map((item, index) => (
        <>
          <div key={index} className='pb-[12px] select-none'>
            <a
              id={item.tourId}
              onClick={(e) => {
                e.preventDefault()
                if (!store.uiStore.getGlobalLoader()) {
                  store.uiStore.setSidebarItem(item)
                  handleSidebarItemClick(item)
                  localStorage.setItem(
                    StringConstants.ACTIVE_TAB,
                    item.id.toString()
                  )
                }
              }}
              className={`relative flex items-center cursor-pointer justify-start py-[8px] px-[20px] w-full bg-transparent hover:bg-opacity-30 ${
                isSidebarExpanded ? '!px-[20px]  ' : 'justify-center h-[48px]'
              } ${
                store.uiStore.getGlobalLoader() ||
                !store.scopeStore.getProjectId()?.length
                  ? 'cursor-not-allowed'
                  : ''
              }`}
              style={{
                color:
                  localStorage.getItem(StringConstants.ACTIVE_TAB) ===
                  item.id.toString()
                    ? palette.colors.textDark1
                    : palette.colors.textDark6,
                backgroundColor:
                  localStorage.getItem(StringConstants.ACTIVE_TAB) ===
                  item.id.toString()
                    ? palette.colors.primary1
                    : 'transparent',
                position: 'relative',
                textDecoration: 'none'
              }}
            >
              {localStorage.getItem(StringConstants.ACTIVE_TAB) ===
                item.id.toString() && (
                <div
                  className={`absolute top-[0px] left-[0px] w-[8px] h-full`}
                  style={{ backgroundColor: palette.colors.primary2 }}
                />
              )}

              <div
                className={`flex items-center`}
                style={{
                  color:
                    localStorage.getItem(StringConstants.ACTIVE_TAB) ===
                    item.id.toString()
                      ? palette.colors.textDark1
                      : palette.colors.textDark6
                }}
              >
                <div className='w-[20px] h-[20px]'>{item.leftIcon}</div>
                {isSidebarExpanded && (
                  <div
                    className={`pl-[12px] w-full flex flex-col max-w-[160px] flex-wrap`}
                  >
                    <DDSTypography.Paragraph
                      size='para'
                      variant={
                        localStorage.getItem(StringConstants.ACTIVE_TAB) ===
                        item.id.toString()
                          ? 'bold'
                          : 'medium'
                      }
                      color={
                        localStorage.getItem(StringConstants.ACTIVE_TAB) ===
                        item.id.toString()
                          ? palette.colors.textDark1
                          : palette.colors.textDark6
                      }
                    >
                      {item.title}
                    </DDSTypography.Paragraph>
                  </div>
                )}
              </div>
            </a>
          </div>
        </>
      ))}
    </div>
  )
})
SidebarLayout.displayName = 'SidebarLayout'
export default observer(SidebarLayout)
