import { DDSButton, DDSTypography } from 'den-design-system'
import { Download, Renew } from '@carbon/icons-react'
import React, { useEffect } from 'react'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'

import { AccessRoleConstants } from '../../../constants/AccessRoleConstants'
import AllExceptions from './Screens/AllExceptions'
import { Button } from '../../../components/New/Button/Button'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import GroupedExceptions from './Screens/GroupedExceptions'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import { ProjectType } from '../../../enum/ProjectType.enum'
import SecureComponent from '../../../components/Layout/SecureComponent'
import StringConstants from '../../../constants/StringConstants'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import { convertJsonToCsvDownload } from '../../../utils/FileUtils'
import { downloadGroupedExceptions } from '../../../api/exception/Exception.service'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useSearchParams } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

const Exceptions: React.FC = () => {
  //API call related functions
  const [tabIndex, setTabIndex] = React.useState<string>('1')
  const [searchParams, setSearchParams] = useSearchParams()
  const store = useStoreContext()
  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.EXCEPTION
    )
  }, [])

  const downloadRecords = () => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)

    const queryParamsForDownload = {
      ...allQueryParams,

      'time-zone': store.userStore.getUserPreference().timeZone
    }

    downloadGroupedExceptions(queryParamsForDownload)
      .then((response: any) => {
        convertJsonToCsvDownload(response.data, 'Grouped_Exception_Data.csv')
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
        store.exceptionStore.setLoading(false)
      })
  }

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.EXCEPTION
    )
  }, [])
  useEffect(() => {
    const tabFromParams = searchParams.get('tab')
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (tabFromParams) {
      setTabIndex(tabFromParams.toString())
    } else {
      setSearchParams({ ...allQueryParams, tab: '1' })
    }
    if (tabIndex === '1') {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: isFullStackProject ? 'Grouped Exceptions' : 'Grouped Errors',
        link:
          replaceUrl(
            routeConstants.EXCEPTIONS,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) + '?tab=1'
      })
    } else if (tabIndex === '2') {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: isFullStackProject ? 'All Exceptions' : 'All Errors',
        link:
          replaceUrl(
            routeConstants.EXCEPTIONS,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) + '?tab=2'
      })
    }
  }, [tabIndex, store.breadcrumbStore.getBreadcrumbsOptions(), projectType])
  const tabOptions = [
    {
      label:
        tabIndex === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {store.scopeStore.getSelectedProject().type ===
            ProjectType.FULL_STACK_ONLY
              ? 'Grouped Exceptions'
              : 'Grouped Errors'}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {store.scopeStore.getSelectedProject().type ===
            ProjectType.FULL_STACK_ONLY
              ? 'Grouped Exceptions'
              : 'Grouped Errors'}
          </DDSTypography.Title>
        ),
      children: <GroupedExceptions />
    },
    {
      label:
        tabIndex === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {store.scopeStore.getSelectedProject().type ===
            ProjectType.FULL_STACK_ONLY
              ? 'All Exceptions'
              : 'All Errors'}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {store.scopeStore.getSelectedProject().type ===
            ProjectType.FULL_STACK_ONLY
              ? 'All Exceptions'
              : 'All Errors'}
          </DDSTypography.Title>
        ),
      children: <AllExceptions />
    }
  ]
  return (
    <div className='flex flex-col w-full relative'>
      <DocumentHeader
        header={
          store.scopeStore.getSelectedProject().type ===
          ProjectType.FULL_STACK_ONLY
            ? HeaderConstants.EXCEPTIONS
            : HeaderConstants.ERROR
        }
      />
      <div className='w-full justify-between relative'>
        <Tabs
          tabOptions={tabOptions}
          className='w-max justify-center'
          id='tabs-grouped-exceptions'
          onChange={(index: any) => {
            if (index !== tabIndex) {
              setTabIndex(index.toString())
              store.exceptionsStoreV2.setCurrentTab(index)
              setSearchParams({
                ...searchParams,
                page: '1',
                tab: index.toString()
              })
            }
          }}
          disabled={store.uiStore.getGlobalLoader()}
          tabOptionsStyle={{
            minWidth: '150px',
            width: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 12px',
            marginRight: '8px'
          }}
          activeTab={tabIndex}
        />
        <div className='flex flex-row gap-x-[8px] absolute top-[4px] right-0'>
          <Button
            id='refresh-button'
            type='neutral'
            size='small'
            startIcon={<Renew color={pallete.colors.textDark10} />}
            onClick={() => store.exceptionStore.setIsRefreshed(true)}
          />
          {tabIndex === '2' &&
            store.exceptionsStoreV2.getAllExceptions().length > 0 && (
              <Button
                id='export to csv'
                label={
                  <DDSTypography.Paragraph
                    variant='semiBold'
                    size='para'
                    className='text-center whitespace-nowrap flex justify-center '
                    color={pallete.colors.textDark10}
                  >
                    Export to CSV
                  </DDSTypography.Paragraph>
                }
                type='neutral'
                disabled={store.userStore.getNoActiveSubscription()}
                size='small'
                startIcon={<Download color={pallete.colors.neuButtonText} />}
                onClick={() => downloadRecords()}
              />
            )}
        </div>
      </div>
    </div>
  )
}

export default SecureComponent(
  observer(Exceptions),
  AccessRoleConstants.MEMBER_LEVEL
)
