import { AtoB, replaceUrl } from '../../../../utils/UrlUtils'
import React, { useEffect, useState } from 'react'
import {
  SeverityColorMapping,
  StatusCodeGraphMapping,
  transformSeverityData,
  transformStatusData
} from '../../../../utils/OverviewUtils'
import { useNavigate, useParams } from 'react-router-dom'

import BatteryGraph from '../../../../components/OverviewPage/BatteryGraph'
import { ChevronRight } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import { Link } from '@mui/material'
import LinkCard from '../../../../components/OverviewPage/LinkCard'
import NoDataImage from '../../../../components/Overview/NoDataImage'
import NoFilterImage from '../../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../../../components/common/NoContent/NoRecord'
import NoRecordImage from '../../../../assets/images/setting.svg'
import OverviewStringConstants from '../../../../constants/OverviewStringConstants'
import OverviewTable from '../../../../components/OverviewPage/OverviewTable'
import { ProjectType } from '../../../../enum/ProjectType.enum'
import Spinner from '../../../../components/common/Spinner/Spinner'
import StringConstants from '../../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../../components/New/Tooltip/Tooltip'
import { getExceptionOverviewAPI } from '../../../../api/overview/Overview.service'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../global/newPallete'
import routeConstants from '../../../../constants/RouteConstants'
import { timeFormats } from '../../../../enum/TIME'
import { timeZone } from '../../../../utils/DateTimeUtils'
import { useStoreContext } from '../../../../store/StoreContext'

interface exceptionOverviewProps {
  navigateToExceptions: any
  setFindExceptionOverview: any
}
const ExceptionsOverview: React.FC<exceptionOverviewProps> = ({
  navigateToExceptions,
  setFindExceptionOverview
}) => {
  const store = useStoreContext()
  const [overviewData, setOverviewData] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { pathIds } = useParams()

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY
  const isSalesforceProject = projectType === ProjectType.SALESFORCE_ONLY

  useEffect(() => {
    if (setFindExceptionOverview) {
      setFindExceptionOverview(findExceptionOverview)
    }
  }, [setFindExceptionOverview])

  useEffect(() => {
    findExceptionOverview()
  }, [store.scopeStore.getScope()])

  const findExceptionOverview = () => {
    if (!isEmpty(store.scopeStore.getScope()['environment-id'])) {
      setLoading(true)
      store.uiStore.setGlobalLoader(true)
      getExceptionOverviewAPI()
        .then((response) => {
          setOverviewData(response.data)
          store.overviewStore.setExceptionData(response.data)
        })
        .catch(() =>
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.OVERVIEW.FETCH_EXCEPTION.ERROR
          })
        )
        .finally(() => {
          store.uiStore.setGlobalLoader(false)
          setLoading(false)
        })
    }
  }

  const topCardData = [
    {
      id: 1,
      title:
        store.scopeStore.getSelectedProject().type ===
        ProjectType.FULL_STACK_ONLY
          ? OverviewStringConstants.TOTAL_EXCEPTIONS
          : OverviewStringConstants.TOTAL_ERRORS,
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark3}
        >
          {overviewData?.totalExceptions}
        </DDSTypography.Title>
      ),
      onClick: () => {
        store.exceptionStore.setActiveTab(1)
        const dateFilters =
          store.overviewStore.getExceptionDateFiltersTimestamps()
        const timeZone = store.userStore.getUserPreference().timeZone
        let query = ''
        if (dateFilters) {
          query = `?created-from=${dateFilters['created-from']}&created-to=${dateFilters['created-to']}&time-zone=${timeZone}`
        }
        navigate(
          replaceUrl(
            routeConstants.GROUPED_ERROR_MONITORING,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) + query
        )
      }
    },
    {
      id: 2,
      title:
        store.scopeStore.getSelectedProject().type ===
        ProjectType.FULL_STACK_ONLY
          ? OverviewStringConstants.MOST_COMMON_EXCEPTION
          : OverviewStringConstants.MOST_COMMON_ERROR,
      content: (
        <Tooltip
          id='mostCommonException-tooltip'
          label={overviewData?.mostCommonException?.name}
          className={`max-w-fit ${
            typeof overviewData?.mostCommonException?.name === 'string' &&
            overviewData?.mostCommonException?.name.length > 37
              ? overviewData?.mostCommonException?.name
              : '!hidden'
          }`}
        >
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            className='truncate max-w-[330px]'
            color={pallete.colors.textDark3}
          >
            {overviewData?.mostCommonException?.name}
          </DDSTypography.Title>
        </Tooltip>
      ),
      onClick:
        overviewData?.mostCommonException?.id &&
        overviewData?.mostCommonException?.id != '-'
          ? () =>
              navigateToExceptions({
                id: overviewData?.mostCommonException?.id,
                tab: '1',
                group: true
              })
          : null
    },
    {
      id: 3,
      title:
        store.scopeStore.getSelectedProject().type ===
        ProjectType.FULL_STACK_ONLY
          ? OverviewStringConstants.MOST_CRITICAL_EXCEPTION
          : OverviewStringConstants.MOST_CRITICAL_ERRORS,
      content: (
        <Tooltip
          id='mostCriticalException-tooltip'
          label={overviewData?.mostCriticalException?.name}
          className={`max-w-fit ${
            typeof overviewData?.mostCriticalException?.name === 'string' &&
            overviewData?.mostCriticalException?.name?.length > 37
              ? overviewData?.mostCriticalException?.name
              : '!hidden'
          }`}
        >
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            className='truncate max-w-[330px]'
            color={pallete.colors.warningRed2}
          >
            {overviewData?.mostCriticalException?.name}
          </DDSTypography.Title>
        </Tooltip>
      ),
      onClick:
        overviewData?.mostCriticalException?.id &&
        overviewData?.mostCriticalException?.id != '-'
          ? () =>
              navigateToExceptions({
                id: overviewData?.mostCriticalException?.id,
                tab: '1',
                group: true
              })
          : null
    }
  ]

  const columns = [
    {
      columnDataStyle: {},
      dataLabel: 'name',
      render: (text: any) => (
        <div className='flex justify-start text-left'>
          <Tooltip
            id={`${text}-tooltip`}
            label={text}
            className={`max-w-[400px] ${
              typeof text === 'string' && text.length > 20 ? text : '!hidden'
            }`}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate max-w-[240px]'
              color={pallete.colors.textDark4}
            >
              {text ?? '-'}
            </DDSTypography.Paragraph>
          </Tooltip>
        </div>
      )
    },
    {
      columnDataStyle: { width: '205px' },
      dataLabel: 'reportedAt',
      render: (text: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            className='whitespace-nowrap'
            color={pallete.colors.textDark4}
          >
            {text
              ? timeZone(
                  store.userStore.getUserPreference().timeZone,
                  text,
                  timeFormats.fullMonthDateYearTime
                )
              : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnDataStyle: { width: '28px' },
      dataLabel: 'id',
      render: (id: any) => (
        <div className='flex justify-center items-center'>
          <ChevronRight
            className='cursor-pointer'
            size={16}
            color={pallete.colors.textDark2}
            onClick={() => navigateToExceptions({ id, tab: '2' })}
          />
        </div>
      )
    }
  ]
  return loading || !overviewData ? (
    <Spinner />
  ) : overviewData.totalExceptions > 0 ? (
    <div className='flex flex-col gap-[40px]'>
      <div className='flex gap-[24px]'>
        {topCardData.map((item) => (
          <LinkCard
            key={item.id}
            linkText={item.title}
            content={item.content}
            onClick={item.onClick}
          />
        ))}
      </div>
      <div className='flex w-full gap-[44px]'>
        <div className='flex flex-col items-start gap-[44px] w-[50%]'>
          <BatteryGraph
            totalCount={Object.values(
              overviewData.exceptionsCountBySeverity
            ).reduce(
              (total: number, count: any) =>
                total + (typeof count === 'number' ? count : 0),
              0
            )}
            title={
              store.scopeStore.getSelectedProject().type ===
              ProjectType.FULL_STACK_ONLY
                ? OverviewStringConstants.EXCEPTIONS_SEVERITY
                : OverviewStringConstants.ERRORS_SEVERITY
            }
            color={SeverityColorMapping}
            data={transformSeverityData(
              overviewData?.exceptionsCountBySeverity
            )}
          />
          <BatteryGraph
            totalCount={
              overviewData?.exceptionsCountByStatus?.resolvedExceptions +
              overviewData?.exceptionsCountByStatus?.unresolvedExceptions
            }
            title={
              store.scopeStore.getSelectedProject().type ===
              ProjectType.FULL_STACK_ONLY
                ? OverviewStringConstants.EXCEPTIONS_STATUS
                : OverviewStringConstants.ERRORS_STATUS
            }
            color={StatusCodeGraphMapping}
            data={transformStatusData(overviewData?.exceptionsCountByStatus)}
          />
        </div>
        <div className='w-[50%]'>
          {overviewData?.recentExceptions?.length > 0 && (
            <OverviewTable
              title={
                store.scopeStore.getSelectedProject().type ===
                ProjectType.FULL_STACK_ONLY
                  ? OverviewStringConstants.RECENT_EXCEPTIONS
                  : OverviewStringConstants.RECENT_ERRORS
              }
              data={overviewData?.recentExceptions}
              columns={columns}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <NoRecord
      imageSrc={
        !overviewData.zeroExceptionsCount ? NoFilterImage : NoRecordImage
      }
      text={
        !overviewData.zeroExceptionsCount ? (
          <>{OverviewStringConstants.NO_RECORDS_FOUND}</>
        ) : (
          <>
            Configure{' '}
            {store.scopeStore.getSelectedProject().type ===
            ProjectType.FULL_STACK_ONLY
              ? OverviewStringConstants.EXCEPTION_TITLE
              : OverviewStringConstants.ERROR_TITLE}
            {''} Monitoring.
            <Link
              style={{ marginLeft: '4px' }}
              href={
                isFullStackProject
                  ? StringConstants.FULLSTACK_URL
                  : isSalesforceProject
                    ? StringConstants.SALESFORCE_URL
                    : StringConstants.MULESOFT
              }
              target='_blank'
              rel='noopener noreferrer'
            >
              Learn more
            </Link>{' '}
          </>
        )
      }
    />
  )
}

export default observer(ExceptionsOverview)
