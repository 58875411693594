import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import ApiGroupingStringConstants from '../../../constants/ApiGroupingStringConstants'
import { DDSTypography } from 'den-design-system'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import StringConstants from '../../../constants/StringConstants'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import ViewAttributes from '../../../components/APIMonitoring/ViewAttributes'
import ViewOverview from '../../../components/APIMonitoring/ViewOverview'
import ViewRequestResponse from '../../../components/APIMonitoring/ViewRequestResponse'
import { findRecord } from '../../../api/apiMonitoring/ApiMonitoring.service'
import { getAllQueryParamsAsObjectFromUrl } from '../../../utils/UrlUtils'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import { useStoreContext } from '../../../store/StoreContext'

const APIGroupedAll: React.FC = () => {
  const [tabIndex, setTabIndex] = useState<string>('1')
  const [searchParams, setSearchParams] = useSearchParams()

  const tab = [
    {
      label:
        tabIndex === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {ApiGroupingStringConstants.OVER_VIEW}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {ApiGroupingStringConstants.OVER_VIEW}
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <ViewOverview />
        </div>
      )
    },
    {
      label:
        tabIndex === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {ApiGroupingStringConstants.REQUEST_RESPONSE}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {ApiGroupingStringConstants.REQUEST_RESPONSE}
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <ViewRequestResponse />
        </div>
      )
    },
    {
      label:
        tabIndex === '3' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {ApiGroupingStringConstants.ATTRIBUTES}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {ApiGroupingStringConstants.ATTRIBUTES}
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <ViewAttributes />
        </div>
      )
    }
  ]
  const store = useStoreContext()

  const { id: $id } = useParams()

  type ApiRecord = { [key: string]: string }
  const pageSize = StringConstants.DEFAULT_TABLE_SIZE
  const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)

  const fetchApiRecord = async () => {
    store.apiDetailsStore.reset()
    store.uiStore.setGlobalLoader(true)
    store.apiDetailsStore.setAPILoading(true)
    if ($id) {
      try {
        const { data } = (await findRecord({
          $id,
          ...queryParams,
          size: pageSize
        })) as {
          data: ApiRecord
        }
        store.apiDetailsStore.setAPILoading(false)
        store.apiDetailsStore.setApiData({ ...data })
      } catch (e) {
        store.apiDetailsStore.setAPILoading(false)
        store.apiDetailsStore.setIsError(true)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      } finally {
        store.uiStore.setGlobalLoader(false)
      }
    }
  }
  useEffect(() => {
    fetchApiRecord()
  }, [])

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.API_MONITORING
    )

    const tabFromParams = searchParams.get('innerTab')
    if (tabFromParams) {
      setTabIndex(tabFromParams.toString())
    }
  }, [])

  return (
    <>
      <DocumentHeader header={HeaderConstants.API_MONITORING} />
      <div className='flex flex-col'>
        <div className='flex flex-row w-full'>
          <div className='w-full'>
            <Tabs
              tabOptionsStyle={{
                minWidth: '120px',
                display: 'flex',
                padding: '8px 12px ',
                justifyContent: 'center',
                alignItems: 'center',
                userSelect: 'none',
                width: 'auto',
                marginRight: '8px'
              }}
              disabled={
                store.apiDetailsStore.getAPILoading() &&
                store.uiStore.getGlobalLoader()
              }
              tabOptions={tab}
              id='tabs-grouped-api'
              onChange={(index: any) => {
                if (tabIndex != index) {
                  setTabIndex(index.toString())
                  const queryParams = getAllQueryParamsAsObjectFromUrl(
                    location.search
                  )
                  if (queryParams['contextSwitcherTab']) {
                    delete queryParams['contextSwitcherTab']
                  }
                  setSearchParams({
                    ...queryParams,
                    page: '1',
                    innerTab: index.toString()
                  })
                }
              }}
              activeTab={tabIndex}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(APIGroupedAll)
