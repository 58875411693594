import {} from '../../../constants/ExceptionsPageConstants'

import {
  Bookmark,
  BookmarkFilled,
  ChevronRight,
  Share
} from '@carbon/icons-react'
import { DDSButton, DDSTooltip, DDSTypography } from 'den-design-system'
import {
  ExceptionStatusTypes,
  SeverityTypes
} from '../../../api/exception/Exception.types'
import React, { useEffect, useMemo, useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import {
  getAnException,
  updateAnException
} from '../../../api/exception/Exception.service'
import { isNull, isUndefined } from 'lodash'

import Activity from '../../../components/common/Exceptions/Activity'
import AssignMembers from '../../../components/common/Exceptions/AssignMember'
import ConfirmationPopup from '../../../components/common/Exceptions/ConfirmationPopup'
import DisplayCard from '../../../components/common/Exceptions/DisplayCard'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import Input from '../../../components/New/Input/Input'
import NoDataImage from '../../../components/Overview/NoDataImage'
import NoRecord from '../../../components/common/NoContent/NoRecord'
import NoRecordImage from '../../../assets/images/folder-not-found 1.svg'
import Overview from './Screens/Overview'
import Parser from 'html-react-parser'
import { ProjectType } from '../../../enum/ProjectType.enum'
import SeverityDropDown from '../../../components/common/Exceptions/SeverityDropdown'
import Slider from '../../../components/common/Exceptions/Slider'
import Spinner from '../../../components/common/Spinner/Spinner'
import StatusDropDown from '../../../components/common/Exceptions/StatusDropdown'
import StringConstants from '../../../constants/StringConstants'
import Switch from '../../../components/New/Switch/Switch'
import Table from '../../../components/common/DDS/Table/Table'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import ToggleButton from '../../../components/common/ToggleButtons/ToggleButtons'
import Tooltip from '../../../components/New/Tooltip/Tooltip'
import copy from 'copy-to-clipboard'
import { getAllProjectMembers } from '../../../api/project/Project.service'
import { paginate } from '../../../utils/CommonUtils'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { severityColorMap } from '../../../constants/ExceptionsPageConstants'
import { timeFormats } from '../../../enum/TIME'
import { timeZone } from '../../../utils/DateTimeUtils'
import { useStoreContext } from '../../../store/StoreContext'

const SingleLogView: React.FC = () => {
  const store = useStoreContext()
  const [activeTabIndex, setActiveTabIndex] = useState<string>('1')
  const [exceptionDetails, setExceptionDetails] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const [assignProjectMemberLoading, setAssignProjectMemberLoading] =
    useState<boolean>(false)
  const [currentPage, setCurrentPage] = React.useState<number>(1)

  const { id } = useParams()
  const [linkCopied, SetLinkCopied] = useState<boolean>(false)
  const [assignMemberOptions, setAssignMemberOptions] = useState<any>([])
  const [isPublic, setIsPublic] = useState<boolean>(true)
  const [severityModalOpen, setSeverityModalOpen] =
    React.useState<boolean>(false)

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY

  const navigate = useNavigate()
  const handleCopyLink = (link: string) => {
    copy(link)
    SetLinkCopied(true)
    setTimeout(() => {
      SetLinkCopied(false)
    }, 3000)
  }
  const [searchParams, setSearchParams] = useSearchParams()
  //Severity modal

  const [changeToPreference, setToPreference] = React.useState<any>()
  const updateSeverity = (severity: SeverityTypes) => {
    setLoading(true)
    const isSameSeverity = exceptionDetails.severity === severity

    if (!isSameSeverity) {
      updateAnException({
        severity: severity,
        $exceptionLogId: id as string
      })
        .then(() => {
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.SUCCESS
              : ToastMessageConstants.ERROR.UPDATE_ERROR.SUCCESS
          })
          setLoading(false)
          setSeverityModalOpen(false)
          fetchAnExceptionDetails()
        })
        .catch(() => {
          ToastNotification({
            type: 'error',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.ERROR
              : ToastMessageConstants.ERROR.UPDATE_ERROR.ERROR
          })
          setLoading(false)
        })
    }
  }
  const [toggleLoading, setToggleLoading] = useState<boolean>(false)
  const updateStatus = (status: ExceptionStatusTypes) => {
    const isSameStatus = exceptionDetails.exceptionStatus === status
    if (!isSameStatus) {
      setLoading(true)
      updateAnException({
        exceptionStatus: status,
        $exceptionLogId: id as string
      })
        .then(() => {
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.SUCCESS
              : ToastMessageConstants.ERROR.UPDATE_ERROR.SUCCESS
          })
          fetchAnExceptionDetails()
        })
        .catch(() => {
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.ERROR
              : ToastMessageConstants.ERROR.UPDATE_ERROR.ERROR
          })
          setLoading(false)
        })
    }
  }

  const tableColumn = [
    {
      columnHeaderStyle: { width: '40%' },
      columnDataStyle: { width: '40%' },
      heading: (
        <div className='flex'>
          <DDSTypography.Paragraph size='para' variant='semiBold'>
            Attributes
          </DDSTypography.Paragraph>
        </div>
      ),
      render: (text: any) => (
        <DDSTooltip
          id={`${text}`}
          label={
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark4}
            >
              {text}
            </DDSTypography.Paragraph>
          }
          shape='roundedRectangle'
          position='top'
          className={`override-tooltip-arrow ${
            typeof text === 'string' && text.length > 15 ? text : '!hidden'
          }`}
          style={{
            backgroundColor: pallete.colors.surface1,
            zIndex: 999999,
            maxWidth: '30%'
          }}
        >
          <div className='flex justify-start text-start row items-start'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className={`tooltip-container sm:block overflow-hidden truncate ${store.uiStore.getSidebarExpanded() ? 'max-w-[170px]' : 'max-w-[210px]'}`}
            >
              {text}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      ),
      dataLabel: 'attribute'
    },
    {
      columnHeaderStyle: { width: '60%' },
      columnDataStyle: { width: '60%' },
      heading: (
        <div className='flex'>
          <DDSTypography.Paragraph size='para' variant='semiBold'>
            Values
          </DDSTypography.Paragraph>
        </div>
      ),
      render: (text: any) => (
        <DDSTooltip
          id={`${text}`}
          label={
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark4}
            >
              {text}
            </DDSTypography.Paragraph>
          }
          shape='roundedRectangle'
          position='top'
          className={`override-tooltip-arrow ${
            typeof text === 'string' && text.length > 25 ? text : '!hidden'
          }`}
          style={{
            backgroundColor: pallete.colors.surface1,
            zIndex: 999999,
            maxWidth: '30%'
          }}
        >
          <div className='flex justify-start text-start row items-start w-fit'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className={`tooltip-container sm:block overflow-hidden truncate ${store.uiStore.getSidebarExpanded() ? 'max-w-[270px]' : 'max-w-[320px]'}`}
            >
              {text}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      ),
      dataLabel: 'value'
    }
  ]
  const [view, setView] = React.useState('Raw')

  const [shareModalOpen, setShareModalOpen] = React.useState<boolean>(false)

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.EXCEPTION
    )
  }, [])
  useEffect(() => {
    const tabFromParam = searchParams.get('tab')
    let firstLabelName: string
    if (
      store.scopeStore.getSelectedProject().type ===
        ProjectType.FULL_STACK_ONLY &&
      tabFromParam === '2'
    ) {
      firstLabelName = 'All Exceptions'
    } else if (
      store.scopeStore.getSelectedProject().type === ProjectType.FULL_STACK_ONLY
    ) {
      firstLabelName = 'Grouped Exceptions'
    } else if (
      (store.scopeStore.getSelectedProject().type ===
        ProjectType.SALESFORCE_ONLY ||
        store.scopeStore.getSelectedProject().type ===
          ProjectType.MULESOFT_ONLY) &&
      tabFromParam === '2'
    ) {
      firstLabelName = 'All Errors'
    } else if (
      store.scopeStore.getSelectedProject().type ===
        ProjectType.SALESFORCE_ONLY ||
      store.scopeStore.getSelectedProject().type === ProjectType.MULESOFT_ONLY
    ) {
      firstLabelName = 'Grouped Errors'
    } else {
      firstLabelName = 'Grouped Errors'
    }
    if (!isUndefined(exceptionDetails)) {
      store.breadcrumbStore.setMultipleBreadcrumbsOptions([
        {
          label: <div className='whitespace-nowrap'>{firstLabelName}</div>,
          link:
            routeConstants.GROUPED_ERROR_MONITORING +
            `?page=1&tab=${tabFromParam ? tabFromParam : '1'}`
        },
        {
          label: (
            <Tooltip
              id={`${exceptionDetails.exceptionType}`}
              position='bottom'
              className={`max-w-[45%] ${
                typeof exceptionDetails.exceptionType === 'string' &&
                exceptionDetails.exceptionType.length > 15
                  ? exceptionDetails.exceptionType
                  : '!hidden'
              }`}
              label={exceptionDetails.exceptionType}
            >
              <div className='truncate max-w-[200px]'>
                {exceptionDetails.exceptionType}
              </div>
            </Tooltip>
          ),
          link:
            routeConstants.ERROR_MONITORING.replace(
              ':id',
              exceptionDetails.exceptionGroupId
            ) + `?tab=${tabFromParam ? tabFromParam : '1'}`
        },
        {
          label: (
            <Tooltip
              id='breadcrumb-tooltip'
              position='bottom'
              className='max-w-[45%]'
              label={exceptionDetails.exceptionMessage}
            >
              <div className='truncate max-w-[400px]'>
                {exceptionDetails.exceptionMessage}
              </div>
            </Tooltip>
          ),
          link: '/'
        }
      ])
    }
    const logTabFromParams = searchParams.get('logTab')
    if (logTabFromParams) {
      setActiveTabIndex(logTabFromParams)
    }
  }, [location.search, exceptionDetails])

  const data = useMemo(() => {
    if (
      !exceptionDetails ||
      isNull(exceptionDetails.context) ||
      isUndefined(exceptionDetails.context)
    ) {
      return []
    }

    return Object.keys(exceptionDetails.context).map((item: string) => ({
      attribute: item,
      value: exceptionDetails.context[item]
    }))
  }, [exceptionDetails])

  const exceptionData = useMemo(
    () => paginate(data, currentPage, 4),
    [data, currentPage]
  )
  const tabOptions = [
    {
      label:
        activeTabIndex === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {'Group Overview'}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {'Group Overview'}
          </DDSTypography.Title>
        ),
      children: <Overview />
    },
    {
      label:
        activeTabIndex === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {'Overview'}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {'Overview'}
          </DDSTypography.Title>
        ),
      children: !loading ? (
        <div className='w-full flex flex-col gap-y-[16px] py-[24px] h-fit'>
          <div className='grid grid-cols-2 justify-between gap-x-[16px] h-fit'>
            <DisplayCard
              id='#'
              heading={
                isFullStackProject ? 'Exception Message' : 'Error Message'
              }
              content={exceptionDetails?.exceptionMessage}
            />
            <DisplayCard
              id='#'
              heading='Tags'
              content={
                <div className='flex flex-row gap-x-[8px] gap-y-[8px] flex-wrap'>
                  {exceptionDetails?.tags?.length > 0
                    ? exceptionDetails?.tags.map(
                        (tag: string, index: number) => (
                          <span
                            className='px-[8px] py-[4px]  rounded-[100px] flex items-center'
                            key={index}
                            style={{ backgroundColor: pallete.colors.surface4 }}
                          >
                            <DDSTypography.Paragraph
                              size='caption'
                              variant='medium'
                              color={pallete.colors.textDark4}
                              className='text-center flex items-center'
                            >
                              {tag}
                            </DDSTypography.Paragraph>
                          </span>
                        )
                      )
                    : '-'}
                </div>
              }
            />
          </div>
          <div className='grid grid-cols-2 justify-between gap-x-[16px]'>
            <div
              className={`px-[16px] py-[20px] overflow-y-auto flex flex-col border border-gray-300 rounded-md gap-y-[16px] ${store.uiStore.getSidebarExpanded() ? 'sm:h-[53vh] md:h-[46vh] 2xl:h-[39vh]' : 'sm: h-[50vh] md:h-[46vh] 2xl:h-[39vh]'}`}
            >
              <div className='w-full flex flex-row justify-between'>
                <DDSTypography.Paragraph
                  size='para'
                  variant='regular'
                  color={pallete.colors.textDark5}
                >
                  {isFullStackProject ? 'Exception Stack' : 'Error Stack'}
                </DDSTypography.Paragraph>
                {store.scopeStore.getSelectedProject().type ===
                  ProjectType.FULL_STACK_ONLY &&
                  exceptionDetails?.exceptionStack && (
                    <Slider
                      options={[{ label: 'Raw' }, { label: 'Full' }]}
                      selected={view}
                      onSelect={(option: string) => {
                        setView(option)
                      }}
                    />
                  )}
              </div>
              {exceptionDetails?.exceptionStack ? (
                <div>
                  {view === 'Raw' ? (
                    <div
                      style={{ backgroundColor: pallete.colors.surface6 }}
                      className='p-[16px] rounded-md overflow-scroll h-full'
                    >
                      <DDSTypography.Paragraph
                        size='para'
                        variant='regular'
                        color={pallete.colors.textDark5}
                      >
                        {Parser(exceptionDetails?.exceptionStack || '')}
                      </DDSTypography.Paragraph>
                    </div>
                  ) : (
                    <div
                      style={{ backgroundColor: pallete.colors.surface8 }}
                      className='p-[-16px] rounded-md overflow-scroll h-full'
                    >
                      {exceptionDetails?.stackFrames?.length > 0 ? (
                        exceptionDetails?.stackFrames?.map(
                          (item: any, index: any) =>
                            item.fileFullPath !== '' && (
                              <div
                                key={index}
                                style={{
                                  backgroundColor: pallete.colors.surface6,
                                  padding: '10px',
                                  marginBottom: '10px',
                                  borderRadius: '5px',
                                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                }}
                              >
                                {item.fileFullPath !== '' && (
                                  <DDSTypography.Paragraph
                                    size='para'
                                    variant='regular'
                                    color={pallete.colors.textDark5}
                                    style={{
                                      overflowWrap: 'break-word'
                                    }}
                                  >
                                    {item.fileFullPath}
                                    {!(item.functionName === '') && (
                                      <>
                                        <span> in </span>
                                        {item.functionName}
                                      </>
                                    )}
                                    {item.lineNo && (
                                      <>
                                        <span> at line </span>
                                        {item.columnNo ? (
                                          <>
                                            {item.lineNo}:{item.columnNo}
                                          </>
                                        ) : (
                                          <>{item.lineNo}</>
                                        )}
                                      </>
                                    )}
                                  </DDSTypography.Paragraph>
                                )}
                              </div>
                            )
                        )
                      ) : (
                        <NoRecord
                          imageSrc={NoRecordImage}
                          text={
                            <>
                              {
                                <DDSTypography.Title
                                  type='h5'
                                  className='whitespace-nowrap'
                                >
                                  {'No Stack Frames Found'}
                                </DDSTypography.Title>
                              }
                            </>
                          }
                        />
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <NoRecord
                  imageSrc={NoRecordImage}
                  text={
                    <>
                      {
                        <DDSTypography.Title
                          type='h5'
                          className='whitespace-nowrap'
                        >
                          {isFullStackProject
                            ? 'No Exception Stack'
                            : 'No Error Stack'}
                        </DDSTypography.Title>
                      }
                    </>
                  }
                />
              )}
            </div>
            <div className='px-[16px] py-[20px] flex flex-col border border-gray-300 rounded-md gap-y-[16px] h-[100%]'>
              <DDSTypography.Paragraph
                size='para'
                variant='regular'
                color={pallete.colors.textDark5}
              >
                {isFullStackProject ? 'Exception Context' : 'Error Context'}
              </DDSTypography.Paragraph>
              <>
                {!isNull(exceptionDetails.context) &&
                !isUndefined(exceptionDetails.context) &&
                Object.keys(exceptionDetails.context).length > 0 ? (
                  <Table
                    data={exceptionData}
                    columns={tableColumn}
                    currentPage={currentPage}
                    rowsPerPage={4}
                    totalCount={Object.entries(exceptionDetails.context).length}
                    onPageChange={(page: number) => {
                      const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                        location.search
                      )
                      setSearchParams({
                        ...allQueryParams,
                        page: page.toString()
                      })
                      setCurrentPage(page)
                    }}
                    loading={loading}
                  />
                ) : (
                  <NoRecord
                    imageSrc={NoRecordImage}
                    text={
                      <>
                        {
                          <DDSTypography.Title
                            type='h5'
                            className='whitespace-nowrap'
                          >
                            No Records Found
                          </DDSTypography.Title>
                        }
                      </>
                    }
                  />
                )}
              </>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )
    },
    {
      label:
        activeTabIndex === '3' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {'Activity'}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {'Activity'}
          </DDSTypography.Title>
        ),
      children: <Activity />
    }
  ]

  const fetchAnExceptionDetails = () => {
    setLoading(true)
    getAnException({ $exceptionLogId: id as string }).then((response: any) => {
      setExceptionDetails(response.data.data)
      setIsPublic(response.data.data.isPublic)
      store.exceptionsStoreV2.setCurrenExceptionId(
        response.data.data.exceptionGroupId
      )
      store.uiStore.setGlobalLoader(false)
      setLoading(false)
    })
  }

  const handlePublicShare = () => {
    setToggleLoading(true)
    updateAnException({
      $exceptionLogId: id as string,
      isPublic: !exceptionDetails.isPublic
    }).then((response: any) => {
      setExceptionDetails(response.data)

      setToggleLoading(false)
    })
  }
  const updateBookmark = (bookmarkState: boolean) => {
    setLoading(true)
    const isSameBookmarkState = exceptionDetails.isBookmarked === bookmarkState
    if (!isSameBookmarkState) {
      updateAnException({
        isBookmarked: bookmarkState,
        $exceptionLogId: id as string
      })
        .then(() => {
          fetchAnExceptionDetails()
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.SUCCESS
              : ToastMessageConstants.ERROR.UPDATE_ERROR.SUCCESS
          })
        })
        .catch(() => {
          ToastNotification({
            type: 'error',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.ERROR
              : ToastMessageConstants.ERROR.UPDATE_ERROR.ERROR
          })
          setLoading(false)
        })
    }
  }
  const fetchProjectMembers = () => {
    const projectId = store.scopeStore.getSelectedProject().id
    getAllProjectMembers(projectId as string)
      .then((response: any) => {
        const allMembers = response.data.map((member: any) => {
          return {
            id: member.id,
            name: member.user.firstName + ' ' + member.user.lastName,
            imgUrl: member.user.avatar
          }
        })
        setAssignMemberOptions(allMembers)
      })
      .catch(() => {
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.PROJECT_MEMBER.FIND_ALL_PROJECT_MEMBERS.ERROR
        })
      })
  }

  const handleAssignMembers = (userId: string) => {
    setAssignProjectMemberLoading(true)
    updateAnException({
      $exceptionLogId: id as string,
      assignedTo: userId
    })
      .then((response: any) => {
        fetchAnExceptionDetails()
        fetchProjectMembers()
        ToastNotification({
          type: 'success',
          message: isFullStackProject
            ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.SUCCESS
            : ToastMessageConstants.ERROR.UPDATE_ERROR.SUCCESS
        })
        setAssignProjectMemberLoading(false)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: isFullStackProject
            ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.ERROR
            : ToastMessageConstants.ERROR.UPDATE_ERROR.ERROR
        })
        setAssignProjectMemberLoading(false)
      })
  }

  const handleRemoveMembers = () => {
    setAssignProjectMemberLoading(true)
    updateAnException({
      $exceptionLogId: id as string,
      assignedTo: ''
    })
      .then((response: any) => {
        setAssignMemberOptions([])
        fetchAnExceptionDetails()
        fetchProjectMembers()
        ToastNotification({
          type: 'success',
          message: isFullStackProject
            ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.SUCCESS
            : ToastMessageConstants.ERROR.UPDATE_ERROR.SUCCESS
        })
        setAssignProjectMemberLoading(false)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: isFullStackProject
            ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.ERROR
            : ToastMessageConstants.ERROR.UPDATE_ERROR.ERROR
        })
        setAssignProjectMemberLoading(false)
      })
  }
  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.EXCEPTION
    )
    fetchAnExceptionDetails()
    fetchProjectMembers()
  }, [])
  if (loading && store.uiStore.getGlobalLoader()) return <Spinner />
  return (
    <div className='h-[100%] pt-[24px]'>
      <DocumentHeader
        header={
          store.scopeStore.getSelectedProject().type ===
          ProjectType.FULL_STACK_ONLY
            ? HeaderConstants.EXCEPTIONS
            : HeaderConstants.ERROR
        }
      />
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className=' h-full flex flex-col'>
            <div className='flex flex-row justify-between items-start '>
              <div className='flex gap-y-[12px] flex-col'>
                <div className='flex flex-row gap-x-[8px] items-center'>
                  <div>
                    <Tooltip
                      id='exceptionType-id-tooltip'
                      label={exceptionDetails?.exceptionType}
                      className={`max-w-[500px] ${typeof exceptionDetails?.exceptionType === 'string' && exceptionDetails?.exceptionType.length > 50 ? exceptionDetails?.exceptionType : '!hidden'}`}
                    >
                      <DDSTypography.Title
                        type='h3'
                        variant='semiBold'
                        className='truncate max-w-[800px]'
                      >
                        {exceptionDetails.exceptionType as string}
                      </DDSTypography.Title>
                    </Tooltip>
                  </div>
                  <div className='w-[16px] h-[16px]'>
                    {exceptionDetails.isBookmarked ? (
                      <BookmarkFilled
                        color={pallete.colors.primary2}
                        className={` ${store.userStore.getNoActiveSubscription() && 'cursor-not-allowed pointer-events-none'}  cursor-pointer h-[16px] w-[16px]`}
                        onClick={() => updateBookmark(false)}
                      />
                    ) : (
                      <Bookmark
                        color={pallete.colors.textDark10}
                        className={` ${store.userStore.getNoActiveSubscription() && 'cursor-not-allowed pointer-events-none'}  cursor-pointer h-[16px] w-[16px]`}
                        onClick={() => updateBookmark(true)}
                      />
                    )}
                  </div>
                  <div className='h-[16px] w-[16px]'>
                    <Share
                      color={pallete.colors.textDark10}
                      className='cursor-pointer h-[16px] w-[16px]'
                      onClick={() => setShareModalOpen(true)}
                    />
                  </div>
                </div>
                <div className='flex flex-row gap-x-[12px]'>
                  <div className='flex flex-row items-baseline gap-x-[6px]'>
                    <DDSTypography.Paragraph
                      size='caption'
                      variant='medium'
                      style={{ color: pallete.colors.textDark6 }}
                      className='whitespace-nowrap'
                    >
                      Reported at:
                    </DDSTypography.Paragraph>
                    <DDSTypography.Paragraph
                      size='para'
                      variant='medium'
                      style={{ color: pallete.colors.textDark3 }}
                      className='whitespace-nowrap'
                    >
                      {timeZone(
                        store.userStore.getUserPreference().timeZone,
                        exceptionDetails.reportedAt,
                        timeFormats.fullMonthDateYearTime
                      )}
                    </DDSTypography.Paragraph>
                  </div>
                  <span className='border-r border-gray-300'></span>
                  <div className='flex flex-row items-baseline gap-x-[6px]'>
                    <DDSTypography.Paragraph
                      size='caption'
                      variant='medium'
                      style={{ color: pallete.colors.textDark6 }}
                      className='whitespace-nowrap'
                    >
                      Client Version:
                    </DDSTypography.Paragraph>
                    <DDSTypography.Paragraph
                      size='para'
                      variant='medium'
                      style={{ color: pallete.colors.textDark3 }}
                    >
                      {exceptionDetails?.clientVersion
                        ? exceptionDetails?.clientVersion
                        : '-'}
                    </DDSTypography.Paragraph>
                  </div>
                </div>
              </div>
              <div className='flex flex-row gap-x-[8px] items-center '>
                <DDSButton
                  label='Report an Incident'
                  type='filled'
                  shape='roundedRectangle'
                  buttonHoverTextColor={pallete.colors.textDark10}
                  size='small'
                  style={{
                    color: pallete.colors.white
                  }}
                  className='whitespace-nowrap'
                  buttonHoverBackgroundColor={pallete.colors.fillButtonhover}
                  onClick={() => {
                    const route = replaceUrl(
                      routeConstants.MANAGE_INCIDENTS_FROM_EXCEPTION,
                      store.scopeStore.getScope()['organization-id'] as string,
                      store.scopeStore.getScope()['project-id'] as string,
                      store.scopeStore.getScope()['environment-id'] as string
                    ).replace(':exceptionLogId', String(id))

                    navigate(route)
                  }}
                  disabled={store.userStore.getNoActiveSubscription()}
                />
                <AssignMembers
                  disableNoSubscription={store.userStore.getNoActiveSubscription()}
                  label='Assign Members'
                  menuPosition='left'
                  disable={exceptionDetails.exceptionStatus == 'Resolved'}
                  options={assignMemberOptions}
                  onclick={(id: string) => handleAssignMembers(id)}
                  assignedUser={exceptionDetails.assignedTo}
                  onremove={handleRemoveMembers}
                  loading={assignProjectMemberLoading}
                />
              </div>
            </div>

            <div className='flex flex-row relative w-full'>
              <div className='w-full '>
                <Tabs
                  tabOptions={tabOptions}
                  className='h-full'
                  tabOptionsStyle={{
                    minWidth: '150px',
                    width: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '8px 12px',
                    marginRight: '8px'
                  }}
                  onChange={(value: string) => {
                    if (activeTabIndex != value) {
                      setActiveTabIndex(value)
                      setSearchParams({
                        ...getAllQueryParamsAsObjectFromUrl(location.search),
                        logTab: value
                      })
                    }
                  }}
                  activeTab={activeTabIndex}
                />
              </div>
              <div className='absolute right-0 flex flex-row gap-x-[12px] '>
                <div>
                  <SeverityDropDown
                    disabled={
                      loading || exceptionDetails.exceptionStatus == 'Resolved'
                    }
                    label={exceptionDetails.severity}
                    onSelect={(value: SeverityTypes) => {
                      if (value !== exceptionDetails.severity) {
                        setToPreference(value)
                        setSeverityModalOpen(true)
                      }
                    }}
                    insideTable={false}
                    disableNoSubscription={store.userStore.getNoActiveSubscription()}
                  />
                </div>
                <div className='override-single-log-status'>
                  <StatusDropDown
                    disabled={loading}
                    label={exceptionDetails.exceptionStatus}
                    onSelect={(status: ExceptionStatusTypes) =>
                      updateStatus(status)
                    }
                    style={{ height: ' 32px' }}
                  />
                </div>
              </div>
            </div>
          </div>
          <ConfirmationPopup
            modalOpen={shareModalOpen}
            setModalOpen={setShareModalOpen}
            title={isFullStackProject ? 'Share Exception' : 'Share Error'}
            primaryButtonText={
              exceptionDetails.isPublic
                ? linkCopied
                  ? 'Copied'
                  : 'Copy Link'
                : ''
            }
            primaryButtonClick={() =>
              handleCopyLink(
                window.location.origin +
                  '/' +
                  'shares' +
                  '/' +
                  exceptionDetails.publicAccessToken
              )
            }
            showFooter={exceptionDetails.isPublic}
            primaryButtonDisabled={toggleLoading || linkCopied}
            loading={toggleLoading}
            content={
              <div className='flex flex-col gap-y-[24px]'>
                <div className='flex flex-row overflow-y-scroll  justify-between'>
                  <div className='flex flex-col w-full gap-y-[4px] items-start justify-start'>
                    <DDSTypography.Title
                      variant='medium'
                      type='h5'
                      className='w-5/6'
                    >
                      {isFullStackProject
                        ? 'Anyone with the link can view the exceptions. To '
                        : 'Anyone with the link can view the errors. To '}
                      <span>
                        {exceptionDetails.isPublic
                          ? 'unshare, disable'
                          : 'share, enable'}{' '}
                        the toggle.
                      </span>
                    </DDSTypography.Title>
                  </div>
                  <div className='flex items-center'>
                    {/* <DDSSwitch
                      onChange={(status) => {
                        updateAnException({
                          $exceptionLogId: id as string,
                          isPublic: status
                        }).then(() => {
                          fetchAnExceptionDetails()
                          setIsPublic(status)
                        })
                      }}
                      value={isPublic}
                    /> */}
                    <Switch
                      onChange={handlePublicShare}
                      id='toggle-share'
                      disabled={toggleLoading}
                      defaultChecked={exceptionDetails.isPublic}
                    />
                  </div>
                </div>
                {exceptionDetails.isPublic ? (
                  <Input
                    id='input-share'
                    onChange={() => {
                      null
                    }}
                    width='100%'
                    name='input'
                    value={
                      window.location.origin +
                      '/' +
                      'shares' +
                      '/' +
                      exceptionDetails.publicAccessToken
                    }
                  />
                ) : null}
              </div>
            }
          />
          <ConfirmationPopup
            modalOpen={severityModalOpen}
            setModalOpen={setSeverityModalOpen}
            title='Severity Type'
            primaryButtonClick={() => updateSeverity(changeToPreference)}
            secondaryButtonClick={() => {
              setSeverityModalOpen(false)
            }}
            primaryButtonDisabled={loading}
            loading={loading}
            secondaryButtonDisabled={loading}
            primaryButtonText='Yes'
            secondaryButtonText='No'
            content={
              <div className='flex flex-col w-full gap-y-[24px] items-center justify-center'>
                <DDSTypography.Title
                  variant='semiBold'
                  type='h4'
                  className='text-center'
                >
                  Are you sure to change the severity type from
                </DDSTypography.Title>
                <div className='flex flex-row justify-center items-center gap-x-[8px] '>
                  <div
                    className='flex items-center justify-center h-[32px] px-[11px] rounded-md'
                    style={{
                      color: 'red !important',
                      border: '1px solid #D9E0EA'
                    }}
                  >
                    <DDSTypography.Title
                      variant='semiBold'
                      type='h4'
                      className='text-center'
                      color={severityColorMap[exceptionDetails.severity]}
                    >
                      {exceptionDetails.severity}
                    </DDSTypography.Title>
                  </div>
                  <div>
                    <ChevronRight size={24} />
                  </div>

                  <div
                    className={`flex items-center justify-center h-[32px] px-[11px] rounded-md`}
                    style={{
                      color: 'red !important',
                      border: '1px solid #D9E0EA'
                    }}
                  >
                    <DDSTypography.Title
                      variant='semiBold'
                      type='h4'
                      className='text-center'
                      color={severityColorMap[changeToPreference]}
                    >
                      {changeToPreference as string}
                    </DDSTypography.Title>
                  </div>
                </div>
              </div>
            }
          />
        </>
      )}
    </div>
  )
}
export default SingleLogView
