import { DDSRadio, DDSTypography } from 'den-design-system'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { ArrowRight } from '@carbon/icons-react'
import { Button } from '../../components/New/Button/Button'
import { CodeSandBoxPopup } from '../NewVersion/CodeSandBox/CodeSandBoxPopup/EnterSandboxPopup'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import Ellipse from '../../assets/images/SandboxEllipse.svg'
import { HeaderConstants } from '../../constants/HeaderConstants'
import HttpConstants from '../../constants/HttpConstants'
import Input from '../../components/New/Input/Input'
import { OrganizationUserRoles } from '../../enum/OrganizationUserRoles.enum'
import { ProjectType } from '../../enum/ProjectType.enum'
import RegexConstants from '../../constants/RegexConstants'
import Robot from '../../assets/images/Robot_left.svg'
import SandboxBackground from '../../assets/images/SandboxDemoBg.svg'
import SandboxConstants from '../../constants/SandboxConstants'
import Select from '../../components/New/Select/Select'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import UnsecureComponent from '../../components/Layout/UnsecureComponent'
import { addQueryParamsToUrl } from '../../utils/UrlGenerator'
import { deleteToken } from '../../utils/AuthUtils'
import { isEmpty } from 'lodash'
import pallete from '../../global/newPallete'
import routeConstants from '../../constants/RouteConstants'
import { sandboxUserEmailCapture } from '../../api/Auth.service'
import { useStoreContext } from '../../store/StoreContext'

const SandboxScreen = () => {
  const [params] = useSearchParams()
  const store = useStoreContext()
  const navigate = useNavigate()
  const projectType = params.get('projectType')
  const projectVariant =
    projectType == 'mulesoft'
      ? ProjectType.MULESOFT_ONLY
      : projectType == 'fullstack'
        ? ProjectType.FULL_STACK_ONLY
        : ProjectType.SALESFORCE_ONLY
  const [codeSandboxPopupOpen, setCodeSandboxPopupOpen] =
    React.useState<boolean>(true)
  const [emailAddress, setEmailAddress] = React.useState<string>('')
  const [emailInvalid, setEmailInvalid] = React.useState<boolean>(false)
  const [memberRole, setMemberRole] = React.useState<string>('owner')
  const [loading, setLoading] = React.useState<boolean>(false)

  const removeSandboxUserDetails = () => {
    localStorage.removeItem('project_type')
    localStorage.removeItem('tour_disabled')
    localStorage.removeItem('sandboxuser_email')
    localStorage.removeItem('sandboxuser_role')
  }

  useEffect(() => {
    if (
      window.location.host.includes('demo.vigilnow.com') &&
      localStorage.getItem('project_type')
    ) {
      const projectVariant =
        projectType == 'mulesoft'
          ? ProjectType.MULESOFT_ONLY
          : projectType == 'fullstack'
            ? ProjectType.FULL_STACK_ONLY
            : ProjectType.SALESFORCE_ONLY
      if (projectVariant !== localStorage.getItem('project_type')) {
        removeSandboxUserDetails()
        deleteToken()
      }
    }
  }, [])

  const getRoles = () => {
    return [
      {
        value: 'owner',
        label: (
          <DDSTypography.Paragraph
            variant='semiBold'
            size='para'
            className='truncate flex items-start'
            color={pallete.colors.textDark4}
          >
            {SandboxConstants.OWNER}
          </DDSTypography.Paragraph>
        )
      },
      {
        value: 'member',
        label: (
          <DDSTypography.Paragraph
            variant='semiBold'
            size='para'
            className='truncate flex items-start'
            color={pallete.colors.textDark4}
          >
            {SandboxConstants.MEMBER}
          </DDSTypography.Paragraph>
        )
      }
    ]
  }
  return (
    <div
      className='relative-sandbox'
      style={{
        backgroundImage: `url(${SandboxBackground})`,
        width: '100vw',
        height: '100vh'
      }}
    >
      <DocumentHeader header={HeaderConstants.SANDBOX} />
      <CodeSandBoxPopup
        modalOpen={codeSandboxPopupOpen}
        setModalOpen={setCodeSandboxPopupOpen}
        primaryButtonText='Yes'
        secondaryButtonText='No'
        secondaryButtonClick={() => {
          setCodeSandboxPopupOpen(false)
        }}
        content={
          <div className='relative'>
            <img
              src={Robot}
              alt='Robot'
              className='absolute right-[14px] bottom-[56px] max-w-full max-h-full object-contain'
            />
            <img
              src={Ellipse}
              alt='Ellipse'
              className='absolute -z-40 right-[1px] max-w-full max-h-full object-cover'
            />

            <div className=' w-full flex justify-between p-[48px_44px]'>
              <div className='w-[55%] flex flex-col gap-[24px]'>
                <div className='flex flex-col gap-[20px]'>
                  <div>
                    <div className='font-weight-800-important'>
                      <DDSTypography.Title
                        style={{
                          fontSize: '46px',
                          lineHeight: '60px'
                        }}
                        type='h1'
                        className='whitespace-nowrap'
                        color={pallete.colors.textDark3}
                      >
                        {SandboxConstants.VIGIL_DEMO}
                      </DDSTypography.Title>
                    </div>

                    <div className='w-[95%]'>
                      <DDSTypography.Title
                        type='h4'
                        variant='medium'
                        color={pallete.colors.textDark5}
                      >
                        {SandboxConstants.TEXT1}
                      </DDSTypography.Title>
                    </div>
                  </div>

                  <div className='flex flex-col gap-[20px]'>
                    <div className='flex flex-col gap-[8px] flex-1 override-input-height'>
                      <DDSTypography.Paragraph
                        size='para'
                        variant='medium'
                        color={pallete.colors.textDark3}
                      >
                        {SandboxConstants.WORK_EMAIL}
                      </DDSTypography.Paragraph>
                      <Input
                        id='work-mail'
                        name='work-mail'
                        height='36px'
                        required
                        type='text'
                        maxLength={150}
                        onChange={(e) => {
                          setEmailAddress(e.target.value)
                        }}
                        placeholder='Enter your work email'
                        validateInput
                        validationRegex={RegexConstants.EMAIL_ADDRESS_CHECK}
                        errorMessage={'Please provide a valid email address'}
                        value={emailAddress}
                        onError={(error) => {
                          setEmailInvalid(error)
                        }}
                      />
                    </div>
                    <div
                      className={`flex flex-col gap-[8px] ${emailInvalid ? 'mt-[12px]' : ''}`}
                    >
                      <DDSTypography.Paragraph
                        size='para'
                        variant='medium'
                        color={pallete.colors.textDark3}
                      >
                        {SandboxConstants.MEMBER_ROLE}
                      </DDSTypography.Paragraph>
                      <div className='flex gap-[8px] items-start self-stretch'>
                        <div
                          className=' w-[230px] flex items-center  gap-[8px] justify-between px-[12px] py-[6px] border rounded-lg cursor-pointer'
                          onClick={() => setMemberRole('owner')}
                          style={{ borderColor: pallete.colors.stroke2 }}
                        >
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: '400',
                              lineHeight: '20px',
                              color:
                                memberRole === 'owner'
                                  ? pallete.colors.textDark3
                                  : pallete.colors.textDark6
                            }}
                          >
                            Owner
                          </span>

                          {/* Custom DDSRadio component */}
                          <div>
                            <DDSRadio
                              value='owner'
                              checked={memberRole === 'owner'}
                              onChange={(e) => {
                                setMemberRole(e.target.value)
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className=' w-[230px] flex items-center  gap-[8px] justify-between px-[12px] py-[6px] border rounded-lg cursor-pointer'
                          onClick={() => setMemberRole('member')}
                          style={{ borderColor: pallete.colors.stroke2 }}
                        >
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: '400',
                              lineHeight: '20px',
                              color:
                                memberRole === 'member'
                                  ? pallete.colors.textDark3
                                  : pallete.colors.textDark6
                            }}
                          >
                            Member
                          </span>

                          {/* Custom DDSRadio component */}
                          <div>
                            <DDSRadio
                              value='member'
                              checked={memberRole === 'member'}
                              onChange={(e) => {
                                setMemberRole(e.target.value)
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {/* <Select
                    id='roles-select'
                    name='Roles'
                    size='large'
                    placeholder='Select your role'
                    options={getRoles()}
                    required
                    searchable={false}
                    onSelect={(val: string) => {
                      setMemberRole(val)
                    }}
                  /> */}
                    </div>
                  </div>
                </div>
                <div className=''>
                  <Button
                    id='add-links'
                    type='filled'
                    size='medium'
                    label='Enter Sandbox'
                    endIcon={
                      <ArrowRight size={16} color={pallete.colors.textDark1} />
                    }
                    loading={loading}
                    disabled={
                      emailAddress.trim().length == 0 ||
                      emailInvalid ||
                      isEmpty(memberRole) ||
                      store.uiStore.getGlobalLoader() ||
                      loading
                    }
                    onClick={() => {
                      setLoading(true)
                      sandboxUserEmailCapture({
                        email: emailAddress,
                        role:
                          memberRole == 'member'
                            ? OrganizationUserRoles.MEMBER
                            : OrganizationUserRoles.OWNER,
                        projectType: projectVariant
                      })
                        .then((response: any) => {
                          const url = addQueryParamsToUrl(
                            routeConstants.REDIRECT,
                            {
                              token: response.data.accessToken
                            },
                            HttpConstants.GET_METHOD
                          )
                          setLoading(false)
                          localStorage.setItem('tour_disabled', 'false')
                          localStorage.setItem('project_type', projectVariant)
                          localStorage.setItem(
                            'sandboxuser_email',
                            response.data.email
                          )
                          localStorage.setItem(
                            'sandboxuser_role',
                            response.data.role
                          )
                          navigate(url)
                        })
                        .catch((err) => {
                          console.log('err-----', err)
                          setLoading(false)
                          ToastNotification({
                            type: 'error',
                            message: err
                          })
                        })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default UnsecureComponent(SandboxScreen)
