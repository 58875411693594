import React, { useEffect, useState } from 'react'

import BatteryGraph from '../../../../components/OverviewPage/BatteryGraph'
import { ChevronRight } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import { Link } from '@mui/material'
import LinkCard from '../../../../components/OverviewPage/LinkCard'
import NoDataImage from '../../../../components/Overview/NoDataImage'
import NoFilterImage from '../../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../../../components/common/NoContent/NoRecord'
import NoRecordImage from '../../../../assets/images/setting.svg'
import OverviewStringConstants from '../../../../constants/OverviewStringConstants'
import OverviewTable from '../../../../components/OverviewPage/OverviewTable'
import { ProjectType } from '../../../../enum/ProjectType.enum'
import Spinner from '../../../../components/common/Spinner/Spinner'
import StringConstants from '../../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../../components/New/Tooltip/Tooltip'
import { color } from '../../../../utils/ApiStatusColorCode'
import { getAPIMonitoringOverviewAPI } from '../../../../api/overview/Overview.service'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../global/newPallete'
import { timeMetric } from '../../../../utils/DateTimeUtils'
import { useParams } from 'react-router-dom'
import { useStoreContext } from '../../../../store/StoreContext'

interface apiOverviewProps {
  navigateToAPI: any
  setFindAPIOverview: any
}
const APIMonitoringOverview: React.FC<apiOverviewProps> = ({
  navigateToAPI,
  setFindAPIOverview
}) => {
  const store = useStoreContext()
  const [overviewData, setOverviewData] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const { pathIds } = useParams()

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY
  const isSalesforceProject = projectType === ProjectType.SALESFORCE_ONLY

  useEffect(() => {
    findAPIOverview()
  }, [store.scopeStore.getScope()])

  useEffect(() => {
    if (setFindAPIOverview) {
      setFindAPIOverview(findAPIOverview)
    }
  }, [setFindAPIOverview])

  const findAPIOverview = () => {
    if (
      !isEmpty(store.scopeStore.getScope()['environment-id']) &&
      store.scopeStore.getSelectedProject().type === ProjectType.FULL_STACK_ONLY
    ) {
      setLoading(true)
      store.uiStore.setGlobalLoader(true)
      getAPIMonitoringOverviewAPI()
        .then((response) => {
          setOverviewData(response.data)
          store.overviewStore.setAPIData(response.data)
        })
        .catch((e) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.OVERVIEW.FETCH_API.ERROR
          })
        })
        .finally(() => {
          store.uiStore.setGlobalLoader(false)
          setLoading(false)
        })
    }
  }
  const topCardData = [
    {
      id: 1,
      title: OverviewStringConstants.MOST_REQUESTED_API,
      content: (
        <Tooltip
          id='most-requested-api-tooltip'
          label={overviewData?.mostCommonApi?.urlTemplate}
          className={`max-w-fit ${
            typeof overviewData?.mostCommonApi?.urlTemplate === 'string' &&
            overviewData?.mostCommonApi?.urlTemplate.length > 20
              ? overviewData?.mostCommonApi?.urlTemplate
              : '!hidden'
          }`}
        >
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            className='truncate max-w-[325px]'
            color={pallete.colors.textDark3}
          >
            {overviewData?.mostCommonApi?.urlTemplate ?? '-'}
          </DDSTypography.Title>
        </Tooltip>
      ),
      secondaryContent: overviewData?.mostCommonApi?.urlTemplate ? (
        <div className='flex items-center gap-[4px]'>
          <DDSTypography.Paragraph
            size='para'
            variant='regular'
            color={pallete.colors.textDark5}
          >
            {OverviewStringConstants.CALLS}:
          </DDSTypography.Paragraph>
          <DDSTypography.Paragraph
            size='para'
            variant='semiBold'
            color={pallete.colors.textDark2}
          >
            {overviewData?.mostCommonApi?.noOfLogs ?? '-'}
          </DDSTypography.Paragraph>
        </div>
      ) : null,
      onClick:
        overviewData?.mostCommonApi?.id &&
        overviewData?.mostCommonApi?.id != '-'
          ? () => {
              navigateToAPI({
                id: overviewData?.mostCommonApi?.id,
                group: true
              })
            }
          : null
    },
    {
      id: 2,
      title: OverviewStringConstants.MOST_FAILED_API,
      content: (
        <Tooltip
          id='most-failed-api-tooltip'
          label={overviewData?.mostFailedApi?.urlTemplate}
          className={`max-w-fit ${
            typeof overviewData?.mostFailedApi?.urlTemplate === 'string' &&
            overviewData?.mostFailedApi?.urlTemplate.length > 20
              ? overviewData?.mostFailedApi?.urlTemplate
              : '!hidden'
          }`}
        >
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            className='truncate max-w-[325px]'
            color={pallete.colors.textDark3}
          >
            {overviewData?.mostFailedApi?.urlTemplate ?? '-'}
          </DDSTypography.Title>
        </Tooltip>
      ),
      secondaryContent: overviewData?.mostFailedApi?.urlTemplate ? (
        <div className='flex items-center gap-[4px]'>
          <DDSTypography.Paragraph
            size='para'
            variant='regular'
            color={pallete.colors.textDark5}
          >
            {OverviewStringConstants.CALLS}:
          </DDSTypography.Paragraph>
          <DDSTypography.Paragraph
            size='para'
            variant='semiBold'
            color={pallete.colors.warningRed2}
          >
            {overviewData?.mostFailedApi?.noOfLogs ?? '-'}
          </DDSTypography.Paragraph>
        </div>
      ) : null,
      onClick:
        overviewData?.mostFailedApi?.id &&
        overviewData?.mostFailedApi?.id != '-'
          ? () => {
              navigateToAPI({
                id: overviewData?.mostFailedApi?.id,
                group: true
              })
            }
          : null
    },
    {
      id: 3,
      title: OverviewStringConstants.SLOWEST_RESPONSE_TIME,
      content: (
        <Tooltip
          id='slowest-response-time-api-tooltip'
          label={overviewData?.logWithLowestResponseTime?.urlTemplate}
          className={`max-w-fit ${
            typeof overviewData?.logWithLowestResponseTime?.urlTemplate ===
              'string' &&
            overviewData?.logWithLowestResponseTime?.urlTemplate.length > 20
              ? overviewData?.logWithLowestResponseTime?.urlTemplate
              : '!hidden'
          }`}
        >
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            className='truncate max-w-[325px]'
            color={pallete.colors.textDark3}
          >
            {overviewData?.logWithLowestResponseTime?.urlTemplate ?? '-'}
          </DDSTypography.Title>
        </Tooltip>
      ),
      secondaryContent: overviewData?.logWithLowestResponseTime?.urlTemplate ? (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark2}
        >
          {overviewData?.logWithLowestResponseTime &&
          overviewData?.logWithLowestResponseTime.lowestResponseTime !== 0
            ? timeMetric(
                overviewData?.logWithLowestResponseTime?.lowestResponseTime,
                'ms'
              )
            : '-'}
        </DDSTypography.Title>
      ) : null,
      onClick:
        overviewData?.logWithLowestResponseTime?.id &&
        overviewData?.logWithLowestResponseTime?.id != '-'
          ? () => {
              navigateToAPI({
                id: overviewData?.logWithLowestResponseTime?.id,
                home: false
              })
            }
          : null
    }
  ]
  const sideCardData = [
    {
      id: 1,
      title: OverviewStringConstants.TOTAL_APIs,
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark3}
        >
          {overviewData?.totalLogs ?? '-'}
        </DDSTypography.Title>
      ),
      onClick: () => {
        navigateToAPI({ home: true, tab: '1' })
      }
    },
    {
      id: 2,
      title: OverviewStringConstants.SUCCESSFUL_APIs,
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.warningGreen}
        >
          {overviewData?.successfulLogs ?? '-'}
        </DDSTypography.Title>
      ),
      onClick:
        overviewData?.successfulLogs && overviewData?.successfulLogs !== '-'
          ? () => {
              let queries = ''
              overviewData.successStatusCodes.map(
                (code: any) =>
                  (queries = queries + `response-status-code=${code}&`)
              )
              navigateToAPI({ queries, home: true, tab: '2' })
            }
          : null
    },
    {
      id: 3,
      title: OverviewStringConstants.FAILED_APIs,
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.warningRed2}
        >
          {overviewData?.failedLogs ?? '-'}
        </DDSTypography.Title>
      ),
      onClick:
        overviewData?.failedLogs && overviewData?.failedLogs !== '-'
          ? () => {
              let queries = ''
              overviewData.failedStatusCodes.map(
                (code: any) =>
                  (queries = queries + `response-status-code=${code}&`)
              )
              navigateToAPI({ queries, home: true, tab: '2' })
            }
          : null
    }
  ]
  const columns = [
    {
      columnDataStyle: {},
      dataLabel: 'urlTemplate',
      render: (text: any) => (
        <div className='flex justify-start text-left'>
          <Tooltip
            id={`${text}-tooltip`}
            label={text}
            className={`max-w-fit ${
              typeof text === 'string' && text.length > 145 ? text : '!hidden'
            }`}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate max-w-[890px]'
              color={pallete.colors.textDark4}
            >
              {text ?? '-'}
            </DDSTypography.Paragraph>
          </Tooltip>
        </div>
      )
    },
    {
      columnDataStyle: { width: '84px' },
      dataLabel: 'httpMethod',
      render: (text: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            className='cursor-pointer'
            color={pallete.colors.textDark4}
          >
            {text ?? '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnDataStyle: { width: '28px' },
      dataLabel: 'id',
      render: (id: any) => (
        <div className='flex justify-center items-center'>
          <ChevronRight
            className='cursor-pointer'
            size={16}
            color={pallete.colors.textDark2}
            onClick={() => navigateToAPI({ id })}
          />
        </div>
      )
    }
  ]
  return loading || !overviewData ? (
    <Spinner />
  ) : overviewData.totalLogs !== 0 ? (
    <div className='flex flex-col items-start self-stretch gap-[40px] w-full'>
      <div className='flex items-start self-stretch gap-[32px]'>
        {topCardData.map((item) => (
          <LinkCard
            key={item.id}
            linkText={item.title}
            content={item.content}
            secondaryContent={item.secondaryContent}
            onClick={item.onClick}
          />
        ))}
      </div>
      <div className='flex items-center gap-[44px] self-stretch w-full'>
        <div className='flex items-start gap-[32px] w-[50%]'>
          {sideCardData.map((item) => (
            <LinkCard
              key={item.id}
              linkText={item.title}
              content={item.content}
              onClick={item.onClick}
            />
          ))}
        </div>
        <div className='w-[50%]'>
          <BatteryGraph
            totalCount={Object.values(overviewData?.statusCodeGraph)
              .map((series: any) => series.totalCount)
              .reduce((sum, count) => sum + count, 0)}
            title={OverviewStringConstants.API_RESPONSE_STATUS_CODE}
            color={color}
            data={overviewData?.statusCodeGraph}
          />
        </div>
      </div>
      <div className='w-full'>
        {overviewData?.recentLogs?.length > 0 && (
          <OverviewTable
            title={OverviewStringConstants.API_RECENT}
            data={overviewData?.recentLogs}
            columns={columns}
          />
        )}
      </div>
    </div>
  ) : (
    <NoRecord
      imageSrc={!overviewData.zeroLogsCount ? NoFilterImage : NoRecordImage}
      text={
        !overviewData.zeroLogsCount ? (
          <>{OverviewStringConstants.NO_RECORDS_FOUND}</>
        ) : (
          <>
            Configure API Monitoring.
            <Link
              href={
                isFullStackProject
                  ? StringConstants.FULLSTACK_URL
                  : isSalesforceProject
                    ? StringConstants.SALESFORCE_URL
                    : StringConstants.MULESOFT
              }
              target='_blank'
              rel='noopener noreferrer'
              style={{ marginLeft: '4px' }}
            >
              Learn More
            </Link>{' '}
          </>
        )
      }
    />
  )
}

export default observer(APIMonitoringOverview)
