import {
  AtoB,
  BtoA,
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import React, { useEffect, useState } from 'react'
import { capitalize, isEmpty } from 'lodash'
import {
  findAllEnvironments,
  getEnvironmentFilters,
  updateEnvironment
} from '../../../api/environment/Environment.service'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'
import {
  getAllProjects,
  getProjectById
} from '../../../api/project/Project.service'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'

import Button from '../Button/Button'
import CopyOnClick from '../CopyOnClick/CopyOnClick'
import CreateEnvironmentPopUp from './PopUps/CreateEnvironment'
import CustomErrorToast from '../Toast/CustomErrorToast'
import { DDSTypography } from 'den-design-system'
import DashboardSectionContainer from '../DashboardSectionContainer/DashboardSectionContainer'
import { EnvironmentType } from '../../../enum/environment.enum'
import Filter from '../../Filter/Filter'
import { IoIosTimer } from 'react-icons/io'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import NoData from '../../NoData/NoData'
import { Notification } from '@carbon/icons-react'
import Popup from '../Popup/Popup'
import ProjectEnvHeader from './Subcomponents/ProjectEnvHeader'
import { ProjectType } from '../../../enum/ProjectType.enum'
import StringConstants from '../../../constants/StringConstants'
import Switch from '../../New/Switch/Switch'
import Table from '../Table/Table'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../DDS/Toast/Toast'
import { Tooltip } from '@mui/material'
import { TypographyDDS } from '../Typography/TypographyDDS'
import breakpoints from '../../../global/breakpoints'
import palette from '../../../global/pallete'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import styled from '@emotion/styled'
import { timeFormats } from '../../../enum/TIME'
import { timeZone } from '../../../utils/DateTimeUtils'
import { toast } from 'react-toastify'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useObserver } from 'mobx-react-lite'
import { useStoreContext } from '../../../store/StoreContext'

interface ParaProps {
  padding?: string
  lineHeight?: number
  fontSize?: string
}

const ParaDark = styled.div<ParaProps>`
  padding: ${(props) => props.padding};
  line-height: ${(props) => props.lineHeight};
  font-weight: 600;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12.5px')};
  color: ${palette.colors.textDark};
`
const Container = styled.div`
  font-size: 0.9rem;
  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.85rem;
  }
  @media (max-width ${breakpoints.md}) {
    font-size: 0.7rem;
  }
`

const ButtonWrapper = styled.div`
  font-size: 1rem;
  display: flex;
  justify-content: flex-end;
`

const ElipsisContainer = styled(TypographyDDS.Paragraph)<{ column?: string }>`
  height: 18px;
  width:${({ column }) => (column === 'jobName' ? 'calc(90%);' : 'calc(80%);')}
  padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0 5px 0 5px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
`

// TODO add loader and no data in table component

const ProjectEnvironments = () => {
  const [createEnvPopUp, setCreateEnvPopUp] = useState(false)

  const [environmentFilters, setEnvironmentFilters] = useState([])
  const [currentTab, setCurrentTab] = useState(0)
  const allSearchParams = getAllQueryParamsAsObjectFromUrl(location.search)
  const [projectName, setProjectName] = useState<string>()
  const [popupOpen, setPopupOpen] = useState<boolean>(false)

  const navigate = useNavigate()
  const { projectId, environmentId } = useParams()
  const { pathIds } = useParams()
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [notificationProps, setNotificationProps] = useState<any>({
    fullItem: null,
    checked: false
  })

  const store = useStoreContext()

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY

  const pageSize = StringConstants.DEFAULT_TABLE_SIZE
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.PROJECT_ENVIRONMENTS_CARD
  )
  const TabLabels = [
    { id: 1, label: 'All' },
    { id: 2, label: 'Development' },
    { id: 3, label: 'Staging' },
    { id: 4, label: 'Production' }
    // { id: 5, label: 'Archived' },
  ]

  const isProduction = window.location.host.includes('app.vigilnow.com')

  const getCurrentTab = () => {
    const type = searchParams.get('type')
    const environmentTypes = ['DEVELOPMENT', 'STAGING', 'PRODUCTION']
    if (type && environmentTypes.includes(type)) {
      setCurrentTab(environmentTypes.indexOf(type) + 1)
    } else {
      setCurrentTab(0)
    }
  }

  const handleTabChange = async (selectedTab: number) => {
    gaEventTracker(gaEventConstants.SWITCH_ENVIRONMENT_TABS_ENVIRONMENT_CARD)
    if (selectedTab === 0) {
      const queryParams = getAllQueryParamsAsObjectFromUrl(location.search, [
        'type'
      ])
      setSearchParams(queryParams, { replace: true })
    } else {
      let searchParams = allSearchParams
      if (selectedTab === 1)
        searchParams = { ...searchParams, type: EnvironmentType.DEVELOPMENT }

      if (selectedTab === 2)
        searchParams = { ...searchParams, type: EnvironmentType.STAGING }

      if (selectedTab === 3)
        searchParams = { ...searchParams, type: EnvironmentType.PRODUCTION }

      setSearchParams(
        { ...searchParams, page: StringConstants.DEFAULT_PAGE },
        { replace: true }
      )
    }
    setCurrentTab(selectedTab)
    store.filterStore.setFiltersApplied(true)

    // TODO view archived is not part of v0
    // if (currentTab === 4) {
    //   setData(ProjectEnvDataArchived)
    // }
  }

  const getEnvironments = () => {
    store.environmentStore.setLoading(true)
    const allSearchParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const pageSize = StringConstants.DEFAULT_TABLE_SIZE
    const queryParams: any = {
      ...allSearchParams,
      size: pageSize,
      'expand-created-user': true
    }
    findAllEnvironments(projectId as string, queryParams)
      .then((environments: any) => {
        if (
          environments.data.length > 0 ||
          store.filterStore.getFiltersApplied()
        ) {
          store.filterStore.setFilterDisabled(false)
        } else {
          store.filterStore.setFilterDisabled(true)
        }
        store.environmentStore.setEnvironments(environments.data)
        store.environmentStore.setTotalCount(environments.totalCount)
        store.environmentStore.setCurrentPage(environments.page)
        store.filterStore.setFilters(environments.filters)
        store.environmentStore.setLoading(false)
      })
      .catch((err) => {
        store.environmentStore.setLoading(false)
        store.environmentStore.setEnvironments([])
        store.environmentStore.setTotalCount(0)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
      })
      .finally(() => store.uiStore.setGlobalLoader(false))
  }

  useEffect(() => {
    const project = store.scopeStore
      .getProjects()
      .filter((record: any) => record.id === projectId)
    store.scopeStore.setSelectedProject(project[0])
    store.projectAndEnvironmentStore.setSelectedProject(project[0])

    if (project.length > 0) {
      store.scopeStore.setSelectedProject(project[0])
      store.projectAndEnvironmentStore.setSelectedProject(project[0])
    }
  }, [store.projectStore.getProjects(), store.scopeStore.getSelectedProject()])

  useEffect(() => {
    getProjectById(projectId as string)
      .then((response: any) => {
        setProjectName(response.data.name)
      })
      .catch((err: string) => {
        navigate(
          replaceUrl(
            routeConstants.PROJECT_SETTINGS,
            store.scopeStore.getScope()['organization-id'] as string,
            '',
            ''
          )
        )
        toast.error(
          <CustomErrorToast
            heading={ToastMessageConstants.PROJECT.FIND_PROJECT.ERROR}
            message={err}
          />
        )
      })
  }, [store.scopeStore.getProjects()])

  useEffect(() => {
    if (store.breadcrumbStore.getBreadcrumbsOptions().length === 0) {
      store.breadcrumbStore.addMultipleBreadCrumbOptions([
        { label: 'Projects', link: routeConstants.PROJECT_SETTINGS },
        {
          label: projectName as string,
          link: routeConstants.PROJECT_SETTINGS_GENERAL.replace(
            ':projectId',
            projectId as string
          )
        },
        { label: 'Environments', link: '/' }
      ])
    }
    store.breadcrumbStore.setGoBackLink(
      routeConstants.PROJECT_SETTINGS_GENERAL.replace(
        ':pathIds',
        ('/' + BtoA(store.scopeStore.getOrganizationId() as string)) as string
      ).replace(':projectId', projectId as string)
    )

    return () => {
      store.breadcrumbStore.reset()
    }
  }, [
    store.breadcrumbStore.getBreadcrumbsOptions(),
    store.scopeStore.getSelectedProject()
  ])

  const getFilters = () => {
    getEnvironmentFilters(projectId as string)
      .then((response: any) => {
        setEnvironmentFilters(response.data)
        getCurrentTab()
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
        navigate(
          replaceUrl(
            routeConstants.PROJECT_SETTINGS,
            store.scopeStore.getScope()['organization-id'] as string,
            '',
            ''
          )
        )
      })
  }
  const fetchAllProjects = () => {
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    getAllProjects({ ...queryParams, 'expand-created-user': true })
      .then((response: any) => {
        store.projectStore.setProjects(response.data)
      })
      .catch((error) => {
        toast.error(
          <CustomErrorToast
            heading={ToastMessageConstants.PROJECT.FIND_ALL_PROJECTS.ERROR}
            message={error}
          />
        )
      })
  }

  useEffect(() => {
    store.scopeStore.setOrganizationId(
      AtoB(pathIds as string).split('/')[0] as string
    )
    store.environmentStore.setLoading(true)
    if (isEmpty(environmentFilters)) {
      getFilters()
    }
    if (location.search !== '' || store.filterStore.getFiltersApplied()) {
      getEnvironments()
      fetchAllProjects()
    }
  }, [location.search])

  const handleNotificationPreference = (fullItem: any, checked: boolean) => {
    store.uiStore.setGlobalLoader(true)
    updateEnvironment({
      sendEmailNotifications: checked,
      projectId: fullItem?.projectId,
      environmentId: fullItem?.id
    })
      .then(() => {
        setPopupOpen(false)
        getEnvironments()
        store.uiStore.setGlobalLoader(false)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.ENVIRONMENT.UPDATE.NOTIFICATION.SUCCESS
        })
      })
      .catch((err) => {
        setPopupOpen(false)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
      })
  }

  const tableColumns = [
    ...(currentTab === 0
      ? [
          {
            title: 'Type',
            label: 'type',
            render: (item: any) => (
              <TypographyDDS.Paragraph
                size='para'
                variant='medium'
                color='textDark'
              >
                {item == StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD
                  ? StringConstants.ENVIRONMENT_NAMES.NON_PROD
                  : StringConstants.ENVIRONMENT_NAMES.PROD}
              </TypographyDDS.Paragraph>
            )
          }
        ]
      : []),
    {
      title: isFullStackProject ? 'API Key' : 'Email Address',
      label: isFullStackProject ? 'apiKey' : 'emailAddress',

      render: (item: any, data: any) => {
        return item ? (
          isFullStackProject ? (
            <div style={{ display: 'flex' }}>
              <Tooltip title={item} arrow placement='top'>
                <div
                  style={{
                    maxWidth: '180px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  <ElipsisContainer
                    size='para'
                    variant='medium'
                    color='textDark'
                  >
                    {item}
                  </ElipsisContainer>
                </div>
              </Tooltip>
              <div>
                <CopyOnClick value={item} />
              </div>
            </div>
          ) : data.emailStatus === 'CREATED' ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!isProduction && data.emailInboxId === null ? (
                <div>-</div>
              ) : (
                <>
                  <Tooltip title={item} arrow placement='top'>
                    <div
                      style={{
                        maxWidth: '180px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      <ElipsisContainer
                        size='para'
                        variant='medium'
                        color='textDark'
                      >
                        {item}
                      </ElipsisContainer>
                    </div>
                  </Tooltip>

                  <div style={{ marginLeft: '8px' }}>
                    <CopyOnClick value={item} />
                  </div>
                </>
              )}
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {!isProduction && data.emailInboxId === '' ? (
                <div>-</div>
              ) : (
                <>
                  <Tooltip
                    title='E-mail inbox is getting initialised'
                    arrow
                    placement='top'
                  >
                    <div style={{ maxWidth: '200px' }}>
                      <IoIosTimer size={20} />
                    </div>
                  </Tooltip>
                </>
              )}
            </div>
          )
        ) : (
          '-'
        )
      }
    },
    {
      title: 'Notifications',
      label: 'sendEmailNotifications',
      render: (item: any, fullItem: any) => (
        <div className='flex items-center justify-center'>
          <Switch
            id='notification-toggle'
            value={item}
            onChange={(checked: boolean) => {
              if (!checked) {
                !popupOpen && setPopupOpen(true)
                setNotificationProps({
                  fullItem: fullItem,
                  checked: checked
                })
              } else {
                handleNotificationPreference(fullItem, checked)
              }
            }}
            disabled={store.userStore.getNoActiveSubscription()}
          />
        </div>
      )
    },
    {
      title: '',
      label: 'recordDetails',
      render: (item: any, fullItem: any) => (
        <ButtonWrapper>
          <Button
            variant='outlined'
            size='small'
            endIcon={<MdOutlineKeyboardArrowRight />}
            onClick={() => {
              navigate(`${pathname}/${fullItem.id}`)
            }}
          >
            View
          </Button>
        </ButtonWrapper>
      )
    }
  ]

  return useObserver(() => (
    <>
      <Container>
        <DashboardSectionContainer
          headerText='Environments'
          padding='1.875em 1.25em'
          customHeader={
            <div style={{ flexDirection: 'row' }}>
              <ProjectEnvHeader
                tabLabels={TabLabels}
                setCurrentTab={(tab) => handleTabChange(tab)}
                currentTab={currentTab}
                setCreateEnvPopUp={setCreateEnvPopUp}
                loading={store.environmentStore.getLoading()}
              />
            </div>
          }
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              {environmentFilters.length > 0 && (
                <Filter options={environmentFilters} disabled />
              )}
            </div>

            <div>
              {store.environmentStore.getEnvironments().length !== 0 ||
              store.environmentStore.getLoading() ? (
                <Table
                  columns={tableColumns}
                  data={store.environmentStore.getEnvironments()}
                  rowsPerPage={pageSize}
                  currentPage={store.environmentStore.getCurrentPage()}
                  onPageChange={(number) => {
                    setSearchParams(
                      { ...allSearchParams, page: number.toString() },
                      { replace: true }
                    )
                    if (number !== store.environmentStore.getCurrentPage())
                      store.environmentStore.setEnvironments([])
                  }}
                  totalCount={store.environmentStore.getTotalCount()}
                  isLoading={
                    store.environmentStore.getLoading() ||
                    store.uiStore.getGlobalLoader()
                  }
                  showCount={false}
                  showPagination={false}
                />
              ) : (
                <NoData
                  onClick={() => setCreateEnvPopUp(true)}
                  emptyText={false}
                  message='Add environments you want to monitor in!'
                />
              )}
            </div>
          </div>
        </DashboardSectionContainer>
        {/* PopUp Section */}
        {createEnvPopUp && (
          <CreateEnvironmentPopUp
            open={createEnvPopUp}
            handleClose={() => {
              setCreateEnvPopUp(false)
            }}
            refreshData={() => {
              getFilters()
              getEnvironments()
              setCurrentTab(0)
            }}
          />
        )}
      </Container>
      <Popup
        open={popupOpen}
        handleClose={() => {
          setPopupOpen(false)
        }}
        headerIcon={<Notification />}
        headerText='Notifications'
        height='220px'
      >
        <div className='flex items-center justify-center h-[100px]'>
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            style={{ maxWidth: '600px', lineHeight: '2', textAlign: 'center' }}
            color={pallete.colors.textDark3}
          >
            {
              StringConstants.NOTIFICATION_PREFERENCE_DELETE_POPUP
                .DISABLE_TOGGLE
            }{' '}
            <span style={{ fontWeight: '700' }}>
              {notificationProps?.fullItem?.name}
            </span>{' '}
            Environment
          </DDSTypography.Title>
        </div>
        <div className='flex justify-center gap-[8px]'>
          <Button
            size='small'
            variant='contained'
            disabled={store.uiStore.getGlobalLoader()}
            onClick={() => {
              setPopupOpen(false)
            }}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='semiBold'
              color='white'
            >
              No
            </DDSTypography.Paragraph>
          </Button>
          <Button
            size='small'
            variant='contained'
            disabled={store.uiStore.getGlobalLoader()}
            color='error'
            onClick={() =>
              handleNotificationPreference(
                notificationProps.fullItem,
                notificationProps.checked
              )
            }
          >
            <DDSTypography.Paragraph
              size='para'
              variant='semiBold'
              color='white'
            >
              Yes
            </DDSTypography.Paragraph>
          </Button>
        </div>
      </Popup>
    </>
  ))
}

export default ProjectEnvironments
