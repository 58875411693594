import React, { useEffect, useState } from 'react'
import {
  UrlWithOnlyOrgId,
  getAllQueryParamsAsObjectFromUrl
} from '../../../utils/UrlUtils'
import {
  acceptUserInvite,
  findAllMyUserInvites,
  rejectUserInvite
} from '../../../api/userInvites/UserInvites.service'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button } from '../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import HttpConstants from '../../../constants/HttpConstants'
import { InvitePopup } from '../Components/InvitesPopup'
import NoRecord from '../../../components/common/NoContent/NoRecord'
import { OrganizationUserRoles } from '../../../enum/OrganizationUserRoles.enum'
import Spinner from '../../../components/common/Spinner/Spinner'
import StringConstants from '../../../constants/StringConstants'
import Table from '../../../components/common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import { addQueryParamsToUrl } from '../../../utils/UrlGenerator'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import successIcon from '../../../assets/images/error 5.svg'
import { timeFormats } from '../../../enum/TIME'
import { timeZone } from '../../../utils/DateTimeUtils'
import { useStoreContext } from '../../../store/StoreContext'

const Invites = () => {
  const store = useStoreContext()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const navigate = useNavigate()
  const [openInvitePopup, setOpenInvitePopup] = React.useState<boolean>(false)
  const [selectedInvite, setSelectedInvite] = React.useState<any>()

  const [totalCount, setTotalCount] = useState<number>(0)

  const [searchParams, setSearchParams] = useSearchParams()

  const [page, setPage] = useState<number>(
    parseInt(searchParams.get('page') as string) || StringConstants.DEFAULT_PAGE
  )
  const pageSize = StringConstants.DEFAULT_TABLE_SIZE

  const [loading, setLoading] = React.useState<boolean>(false)

  const [data, setData] = useState<any>([])

  const [acceptLoading, setAcceptLoading] = React.useState<boolean>(false)
  const [rejectLoading, setRejectLoading] = React.useState<boolean>(false)

  useEffect(() => {
    searchParams.set('page', page.toString())
    setSearchParams(searchParams)
    fetchAllMyUserInvites()
  }, [location.search])

  function fetchAllMyUserInvites() {
    setLoading(true)
    findAllMyUserInvites(page)
      .then((userInvitesData: any) => {
        setData(userInvitesData.data)
        setTotalCount(userInvitesData.totalCount)
        setPage(userInvitesData.page)
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
      .catch((err: any) => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.USER_INVITE.GET_ALL_MY_USER_INVITES.ERROR
        })
      })
  }
  const handleRejectUserInvite = (token: any) => {
    setRejectLoading(true)
    rejectUserInvite({ $token: token as string })
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.USER_INVITE.REJECT_USER_INVITE.SUCCESS
        })
        fetchAllMyUserInvites()
        setRejectLoading(false)
        setOpenInvitePopup(false)
      })
      .catch((err: string) => {
        setRejectLoading(false)
        setOpenInvitePopup(false)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.USER_INVITE.REJECT_USER_INVITE.ERROR
        })
      })
  }
  const handleAcceptUserInvite = (token: any, organizationId = '') => {
    setAcceptLoading(true)

    acceptUserInvite({ $token: token as string })
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.USER_INVITE.ACCEPT_USER_INVITE.SUCCESS
        })
        localStorage.removeItem(
          StringConstants.LOCAL_STORAGE.CURRENT_PAGE_DETAILS
        )
        localStorage.removeItem(
          StringConstants.LOCAL_STORAGE.CURRENT_PAGE_DETAILS
        )
        fetchAllMyUserInvites()
        setAcceptLoading(false)
        setOpenInvitePopup(false)
        store.scopeStore.setOrganizationId(organizationId)
        store.scopeStore.setProjectId('')
        store.scopeStore.setEnvironmentId('')
        navigate(
          addQueryParamsToUrl(
            routeConstants.REDIRECT,
            token.role !== OrganizationUserRoles.BILLING
              ? {
                  redirect: UrlWithOnlyOrgId(
                    routeConstants.MEMBER_SETTINGS,
                    store
                  )
                }
              : {
                  redirect: UrlWithOnlyOrgId(
                    routeConstants.SUBSCRIPTION_SETTINGS,
                    store
                  )
                },
            HttpConstants.GET_METHOD
          )
        )
      })
      .catch((err: string) => {
        setAcceptLoading(false)
        setOpenInvitePopup(false)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.USER_INVITE.ACCEPT_USER_INVITE.ERROR
        })
      })
  }

  const columns = [
    {
      columnHeaderStyle: { width: '50%' },
      columnDataStyle: { width: '50%' },
      heading: (
        <div>
          <DDSTypography.Paragraph
            size='para'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Invites
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: '',
      render: (item: any, fullItem: any) => (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
          >
            {fullItem?.inviter?.firstName +
              ' ' +
              fullItem?.inviter?.lastName +
              ' invited you to join ' +
              fullItem?.organization?.name +
              ' as a '}
            <span style={{ fontWeight: 700, fontSize: '14px' }}>
              {fullItem?.role}
            </span>
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '35%' },
      columnDataStyle: { width: '35%' },
      heading: '',
      dataLabel: '',
      render: (item: any, fullItem: any) => {
        return (
          <div className='flex justify-start text-start row items-start'>
            <DDSTypography.Paragraph
              size='para'
              variant='bold'
              color={pallete.colors.textDark4}
            >
              {fullItem?.project?.name}
            </DDSTypography.Paragraph>
            <DDSTypography.Paragraph
              size='para'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {timeZone(
                store.userStore.getUserPreference().timeZone,
                fullItem?.createdAt,
                timeFormats.monthDateYear
              )}
            </DDSTypography.Paragraph>
          </div>
        )
      }
    },
    {
      columnHeaderStyle: { width: '15%' },
      columnDataStyle: { width: '15%' },
      heading: '',
      dataLabel: '',
      render: (item: any, fullItem: any) => (
        <Button
          id='mark-as-button'
          label='Review Invite'
          onClick={() => {
            console.log('hello')
            setOpenInvitePopup(true)
            setSelectedInvite({
              role: fullItem?.role,
              project: fullItem?.project?.name,
              organization: fullItem?.organization?.name,
              token: fullItem?.token,
              projectId: fullItem?.organization?.id
            })
          }}
          type='outline'
        />
      )
    }
  ]
  return (
    <div className='flex flex-col py-[24px]'>
      <div>
        {loading ? (
          <Spinner />
        ) : data.length !== 0 ? (
          <Table
            data={data}
            columns={columns}
            totalCount={totalCount}
            rowsPerPage={10}
            currentPage={currentPage}
            loading={loading}
            tableStyle={{
              borderColor: pallete.colors.stroke2
            }}
            onPageChange={(page: number) => {
              const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                location.search
              )
              setSearchParams({ ...allQueryParams, page: page.toString() })
              setCurrentPage(page)
            }}
            className={`!border-[${pallete.colors.stroke2}] `}
          />
        ) : (
          <NoRecord
            text='You’re all caught up.'
            imageSrc={successIcon}
            style={{ height: '70vh' }}
          />
        )}
      </div>
      {openInvitePopup && (
        <InvitePopup
          showHeader
          loading={loading}
          title={
            selectedInvite?.project ? 'Project Invite' : 'Organization Invite'
          }
          modalOpen={openInvitePopup}
          setModalOpen={setOpenInvitePopup}
          primaryButtonText='Accept Invite'
          secondaryButtonText='Reject Invite'
          // editLoading={loading}
          primaryButtonDisabled={acceptLoading || rejectLoading}
          secondaryButtonDisabled={acceptLoading || rejectLoading}
          primaryButtonClick={() => {
            handleAcceptUserInvite(
              selectedInvite?.token,
              selectedInvite?.projectId
            )
          }}
          secondaryButtonClick={() => {
            handleRejectUserInvite(selectedInvite?.token)
          }}
          showFooter={true}
          content={
            <div className='flex flex-col p-[24px] gap-[24px]'>
              <div>
                <DDSTypography.Title
                  variant='semiBold'
                  type='h4'
                  color={pallete.colors.textDark3}
                >
                  {`Do you want to join `}
                  <span style={{ fontWeight: 700 }}>
                    {selectedInvite?.project
                      ? '"Project - ' + selectedInvite?.project + '"'
                      : selectedInvite?.organization}
                  </span>
                  {` as `}
                  <span style={{ fontWeight: 700 }}>
                    {selectedInvite?.role}
                  </span>
                  {`?`}
                </DDSTypography.Title>
              </div>
            </div>
          }
        />
      )}
    </div>
  )
}

export default Invites
