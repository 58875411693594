import {
  AtoB,
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import React, { useEffect, useState } from 'react'
import { cloneDeep, isEmpty, isNull } from 'lodash'
import {
  findConfiguredJob,
  getAllJobLogs,
  getJobLogFilters
} from '../../../api/jobs/Jobs.service'
import { useParams, useSearchParams } from 'react-router-dom'

import { DDSTypography } from 'den-design-system'
import { FilterTypes } from '../../../enum/Filter.enum'
import JMLogs from '../../../components/JobMonitoring/JMOverviewLogs/JMLogs'
import JMOverview from '../../../components/JobMonitoring/JMOverviewLogs/JMOverview'
import JMStringConstants from '../../../constants/JMStringConstants'
import StringConstants from '../../../constants/StringConstants'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import { getFiltersForApi } from '../../../utils/FilterUtils'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useStoreContext } from '../../../store/StoreContext'

const JMOverviewLogs = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const currTab = searchParams.get('tab') ?? '1'
  const prevTab = searchParams.get('previousTab') ?? '1'
  const [activeTab, setActiveTab] = useState<string>(currTab ? currTab : '1')
  const [jobLogfilters, setJobLogFilters] = useState<any>([])
  const store = useStoreContext()
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedJob, setSelectedJob] = useState<any>({})
  const [jobLogs, setJobLogs] = useState<any>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [filtersApplied, setFiltersApplied] = useState(false)
  const { id } = useParams()
  const { pathIds } = useParams()

  useEffect(() => {
    getFiltersForLogs()
    setLoading(false)
  }, [])

  const getFiltersForLogs = () => {
    getJobLogFilters()
      .then((response: any) => {
        setJobLogFilters(response.data)
      })
      .catch((err) => console.log('err', err))
  }

  const findTheConfiguredJob = () => {
    store.uiStore.setGlobalLoader(true)
    if (
      AtoB(pathIds as string).split('/')[1] &&
      AtoB(pathIds as string).split('/')[2]
    ) {
      store.scopeStore.setOrganizationId(
        AtoB(pathIds as string).split('/')[0] as string
      )
      store.scopeStore.setProjectId(
        AtoB(pathIds as string).split('/')[1] as string
      )
      store.scopeStore.setEnvironmentId(
        AtoB(pathIds as string).split('/')[2] as string
      )
    }

    if (
      !isEmpty(store.scopeStore.getScope()['environment-id']) &&
      !isEmpty(store.scopeStore.getScope()['project-id'])
    ) {
      findConfiguredJob({ $jobId: id as string })
        .then((response: any) => {
          setSelectedJob(response.data)
        })
        .finally(() => {
          store.uiStore.setGlobalLoader(false)
        })
    }
  }

  const findJobLogs = () => {
    setLoading(true)
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const filter = getFiltersForApi(FilterTypes.JOB_LOG, cloneDeep(queryParams))
    const payload = {
      $jobId: id as string,
      size: StringConstants.DEFAULT_TABLE_SIZE,
      ...filter
    }
    getAllJobLogs(payload)
      .then((response: any) => {
        setJobLogs(response.data)
        setTotalCount(response.totalCount)
        setFiltersApplied(!isEmpty(response.filters))
        store.filterStore.setFilters(response.filters)
      })
      .catch(() =>
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.JOB_MONITORING.JOB_LOGS.FIND_JOB_LOGS.ERROR
        })
      )
      .finally(() => {
        setLoading(false)
        store.filterStore.setFilterDisabled(false)
      })
  }

  const tabs = [
    {
      label:
        activeTab === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {JMStringConstants.OVERVIEW_TAB}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {JMStringConstants.OVERVIEW_TAB}
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <JMOverview
            currentJob={selectedJob}
            findTheConfiguredJob={findTheConfiguredJob}
            isLoading={loading}
          />
        </div>
      )
    },
    {
      label:
        activeTab === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {JMStringConstants.LOGS_TAB}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {JMStringConstants.LOGS_TAB}
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <JMLogs
            jobId={id as string}
            currentJob={selectedJob}
            jobLogFilters={jobLogfilters}
            jobLogs={jobLogs}
            findJobLogs={findJobLogs}
            totalLogCount={totalCount}
            filtersApplied={filtersApplied}
            findTheConfiguredJob={findTheConfiguredJob}
            isLoading={loading}
          />
        </div>
      )
    }
  ]

  useEffect(() => {
    if (prevTab === '1') {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: JMStringConstants.ALL_JOBS_TAB,
        link:
          replaceUrl(
            routeConstants.JOB_MONITORING,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) +
          '?page=1' +
          '&tab=' +
          prevTab
      })
    } else if (prevTab === '2') {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: JMStringConstants.SUCCESS_JOBS_TAB,
        link:
          replaceUrl(
            routeConstants.JOB_MONITORING,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) +
          '?page=1' +
          '&tab=' +
          prevTab
      })
    } else if (prevTab === '3') {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: JMStringConstants.RUNNING_JOBS_TAB,
        link:
          replaceUrl(
            routeConstants.JOB_MONITORING,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) +
          '?page=1' +
          '&tab=' +
          prevTab
      })
    } else if (prevTab === '4') {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: JMStringConstants.FAILED_JOBS_TAB,
        link:
          replaceUrl(
            routeConstants.JOB_MONITORING,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) +
          '?page=1' +
          '&tab=' +
          prevTab
      })
    }
  }, [])

  useEffect(() => {
    if (activeTab === '1') {
      if (store.breadcrumbStore.getBreadcrumbsOptions().length > 1) {
        store.breadcrumbStore.sliceBreadcrumbOptions(0, 1)
      }
      store.breadcrumbStore.appendBreadCrumbOptions({
        label: JMStringConstants.OVERVIEW_TAB,
        link:
          replaceUrl(
            routeConstants.JOB_MONITORING + ':id',
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) +
          '?tab=1&previousTab=' +
          prevTab
      })
    } else if (activeTab === '2') {
      if (store.breadcrumbStore.getBreadcrumbsOptions().length > 1) {
        store.breadcrumbStore.sliceBreadcrumbOptions(0, 1)
      }
      store.breadcrumbStore.appendBreadCrumbOptions({
        label: JMStringConstants.LOGS_TAB,
        link:
          replaceUrl(
            routeConstants.JOB_MONITORING + ':id',
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) +
          '?tab=2&previousTab=' +
          prevTab
      })
    }
  }, [activeTab])

  return (
    <div key={id} className='flex flex-col w-full'>
      <div className='tab-child flex w-full'>
        <Tabs
          tabOptionsStyle={{
            minWidth: '150px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'stretch'
          }}
          disabled={loading || store.uiStore.getGlobalLoader()}
          activeTab={activeTab}
          onChange={(active) => {
            if (activeTab != active) {
              store.uiStore.setGlobalLoader(true)
              setSearchParams({
                page: '1',
                tab: active,
                previousTab: prevTab
              })
              setActiveTab(active)
            }
          }}
          tabOptions={tabs}
          id='tabs-uc-summary'
        />
      </div>
    </div>
  )
}

export default observer(JMOverviewLogs)
