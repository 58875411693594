import React, { useEffect } from 'react'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'

import AllAPI from '../../../components/APIMonitoring/AllAPI'
import ApiGroupingStringConstants from '../../../constants/ApiGroupingStringConstants'
import { Button } from '../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import GroupedAPI from '../../../components/APIMonitoring/GroupedAPI'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import { Renew } from '@carbon/icons-react'
import StringConstants from '../../../constants/StringConstants'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useSearchParams } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

const APIGroupedAll: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState<string>('1')
  const [searchParams, setSearchParams] = useSearchParams()
  const store = useStoreContext()
  const tabs = [
    {
      label:
        tabIndex === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            Grouped APIs
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            Grouped APIs
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <GroupedAPI />
        </div>
      )
    },
    {
      label:
        tabIndex === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            All APIs
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            All APIs
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <AllAPI />
        </div>
      )
    }
  ]

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.API_MONITORING
    )
  }, [])

  useEffect(() => {
    const tabFromParams = searchParams.get('tab')
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (tabFromParams) {
      setTabIndex(tabFromParams.toString())
    } else {
      setSearchParams({ ...allQueryParams, tab: '1' })
    }
    if (tabIndex === '1') {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: ApiGroupingStringConstants.GROUPED_API,
        link:
          replaceUrl(
            routeConstants.API_MONITORING,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) + '?tab=1'
      })
    } else if (tabIndex === '2') {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: ApiGroupingStringConstants.ALL_API,
        link:
          replaceUrl(
            routeConstants.API_MONITORING,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) + '?tab=2'
      })
    }
  }, [store.breadcrumbStore.getBreadcrumbsOptions(), tabIndex])

  return (
    <>
      <DocumentHeader header={HeaderConstants.API_MONITORING} />
      <div className='flex flex-col w-full'>
        <div className='relative w-full flex justify-between'>
          <div
            style={{
              width: '100%',
              display: 'flex'
            }}
            className='tab-child'
          >
            <Tabs
              tabOptionsStyle={{
                minWidth: '150px',
                width: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px 12px',
                marginRight: '8px'
              }}
              tabOptions={tabs}
              id='tabs-grouped-api'
              onChange={(index: any) => {
                if (tabIndex != index) {
                  setTabIndex(index.toString())
                  setSearchParams({ ...searchParams, tab: index.toString() })
                }
              }}
              activeTab={tabIndex}
            />
          </div>
          <div className='absolute right-[0px]'>
            <Button
              id='outline-button'
              label=' Refresh'
              type='neutral'
              onClick={() => store.apiDetailsStore.setRefresh(true)}
              startIcon={<Renew color={pallete.colors.neuButtonText} />}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(APIGroupedAll)
