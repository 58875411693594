import { DDSModal, DDSTable, DDSTooltip } from 'den-design-system'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { useMediaQuery, useTheme } from '@mui/material'

import { Chart } from 'chart.js'
import { ChevronRight } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import { ProjectType } from '../../../enum/ProjectType.enum'
import React from 'react'
import { Chart as ReactChart } from 'react-chartjs-2'
import StringConstants from '../../../constants/StringConstants'
import Tooltip from '../../New/Tooltip/Tooltip'
import { generateRandomId } from '../../../utils/CommonUtils'
import palette from '../../../global/pallete'
import pallete from '../../../global/newPallete'
import { replaceUrl } from '../../../utils/UrlUtils'
import routeConstants from '../../../constants/RouteConstants'
import styled from '@emotion/styled'
import { useStoreContext } from '../../../store/StoreContext'

interface OverviewDoughnutChartProps {
  labels: string[]
  data: number[]
  colors: string[]
  hideLegend?: boolean
  centerTextTitle?: string
  centerTextContent?: string
  cutout?: string
  showOthers?: boolean
}
interface Legend {
  label: string
  color: string
  onClick?: () => any
  setModel?: () => any
}

const Square = styled.div<{ color: string }>`
  height: 12px;
  width: 16px;
  background-color: ${({ color }) => color};
  display: inline-block;
`
const Legend: React.FC<Legend> = ({ color, label, onClick, setModel }) => {
  const store = useStoreContext()

  return (
    <div className={`flex flex-row gap-x-[8px] justify-start items-center`}>
      {label !== '' && <Square color={color} />}
      <DDSTooltip
        id={generateRandomId()}
        label={label as string}
        className='override-tooltip-arrow border'
        position='left'
        shape='roundedRectangle'
        theme='light'
        style={{ maxWidth: '60%', zIndex: 99999 }}
      >
        <div
          className={`flex w-full justify-center ${label === StringConstants.OTHERS ? 'cursor-pointer' : 'none'}`}
          onClick={setModel}
        >
          <DDSTypography.Paragraph
            color={
              label === StringConstants.OTHERS
                ? color
                : pallete.colors.textDark4
            }
            size='para'
            variant='medium'
            className={`${label === StringConstants.OTHERS && 'flex items-center'} text-wrap truncate  ${store.uiStore.getSidebarExpanded() ? 'md:w-[14vw]  2xl:w-[20vw]' : 'sm:w-[14vw] md:w-[16vw] lg:w-[20vw]  2xl:w-[]'} `}
          >
            {label}{' '}
            {label === StringConstants.OTHERS && (
              <ChevronRight
                color={color}
                className='h-[16px] w-[16px] flex cursor-pointer'
              />
            )}
          </DDSTypography.Paragraph>
        </div>
      </DDSTooltip>
    </div>
  )
}
const OverviewDoughnutChart: React.FC<OverviewDoughnutChartProps> = ({
  data,
  labels,
  colors,
  hideLegend,
  centerTextTitle,
  centerTextContent,
  cutout = '80%',
  showOthers
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const store = useStoreContext()
  const greaterThanLarge = useMediaQuery(theme.breakpoints.up('xl'))

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY

  const routeException = (id: string) => {
    store.exceptionStore.setId(id)
    const route = replaceUrl(
      routeConstants.ERROR_DETAILS,
      store.scopeStore.getScope()['organization-id'] as string,
      store.scopeStore.getScope()['project-id'] as string,
      store.scopeStore.getScope()['environment-id'] as string
    ).replace(':id', String(id))
    const queryParams = { logTab: '2' }
    navigate({ pathname: route, search: `?${createSearchParams(queryParams)}` })
  }

  //code for "Others" data table
  const tableColumns = [
    {
      columnHeaderStyle: { width: '240px' },
      columnDataStyle: { width: '240px' },
      heading: (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            variant='regular'
            size='para'
            color={palette.colors.textDark5}
            className='whitespace-nowrap'
          >
            {isFullStackProject ? 'Exception Message' : 'Error Message'}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'exceptionMessage',
      render: (text: any) => (
        <DDSTooltip
          id={`exception-${Math.random()}`}
          label={
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark4}
            >
              {text}
            </DDSTypography.Paragraph>
          }
          shape='roundedRectangle'
          position='top'
          className='override-tooltip-arrow'
          style={{
            backgroundColor: pallete.colors.surface1,
            zIndex: 999999,
            maxWidth: '90%'
          }}
        >
          <div className='flex justify-start text-start row items-start w-[240px]'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate max-w-[240px]'
            >
              {text ? text : '-'}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    {
      columnHeaderStyle: { width: '100px' },
      columnDataStyle: { width: '100px' },
      heading: (
        <div className='flex justify-center text-center items-center'>
          <DDSTypography.Paragraph
            variant='regular'
            size='para'
            color={palette.colors.textDark5}
            className='whitespace-nowrap'
          >
            Count
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'count',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center '>
          <DDSTypography.Paragraph variant='medium' size='para'>
            {text ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: '',
      dataLabel: 'id',
      render: (id: any, fullItem: any) => (
        <div className='cursor-pointer flex justify-end items-end'>
          <ChevronRight
            size={16}
            onClick={() => {
              routeException(id)
            }}
            color={pallete.colors.FillButton}
          />
        </div>
      )
    }
  ]
  const [tableData, setTableData] = React.useState<any[]>(
    store.exceptionsStoreV2
      .getStatisticsData()
      .others.map((error: any, index: any) => {
        return {
          key: index,
          id: error.id,
          exceptionMessage: error.message,
          count: error.count
        }
      })
  )
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  function drawText(ctx: any, text: any, x: any, y: any, config: any) {
    config = config || {}
    text = typeof text === 'string' ? text : ''

    const fontColor = config.fontColor || '#000000'
    const fontSize = config.fontSize || 12
    const fontFamily = config.fontFamily || 'Arial'
    const fontWeight = config.fontWeight || 'normal'
    const lineHeight = config.lineHeight || fontSize + 2
    const textAlign = config.textAlign
    const textBaseline = config.textBaseline
    const texts = text.split('\n') || ''
    let widerLineWidth = 0
    ctx.save()
    ctx.fillStyle = fontColor
    ctx.font = [fontWeight, fontSize + 'px', fontFamily].join(' ')
    if (textAlign) ctx.textAlign = textAlign
    if (textBaseline) ctx.textBaseline = textBaseline
    texts.forEach(function (value: any, i: any) {
      const lineWidth = ctx.measureText(value).width
      widerLineWidth = lineWidth > widerLineWidth ? lineWidth : widerLineWidth
      const fixedY = y + lineHeight * i
      ctx.fillText(value, x, fixedY)
    })
    ctx.restore()
    return {
      width: widerLineWidth,
      height: lineHeight * (texts.length - 1)
    }
  }
  const centerText = {
    id: 'centerText',
    afterDraw: function (chart: any, _: any, options: any) {
      if (options.text1 && options.text2) {
        const ctx = chart.ctx
        const canvas = chart.canvas
        const posX = canvas.width / (greaterThanLarge ? 2 : 4)
        const posY = canvas.height / (greaterThanLarge ? 2 : 4)
        drawText(ctx, options.text1, posX, posY - 15, {
          fontColor: palette.colors.textDark,
          fontSize: 14,
          textAlign: 'center'
        })
        drawText(ctx, options.text2, posX, posY + 11, {
          fontWeight: palette.fontWeight.bold,
          fontColor: palette.colors.primary,
          fontSize: 24,
          textAlign: 'center',
          textBaseline: 'hanging'
        })
      }
    }
  }
  Chart.register(centerText)
  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: colors,
        borderWidth: 0,
        rotation: 90
      }
    ]
  }

  const options = {
    cutout,
    plugins: {
      legend: {
        display: false
      },
      centerText: {
        text1: centerTextTitle,
        text2: centerTextContent,
        fontStyle: 'Inter',
        sidePadding: 20,
        minFontSize: 25,
        lineHeight: 25
      },
      tooltip: {
        enabled: false
      }
    }
  }

  return (
    <div className='flex flex-row gap-x-[24px] w-fit items-center'>
      <div>
        <ReactChart
          type={'doughnut'}
          data={chartData}
          options={options}
          key={window.innerWidth}
          style={{ height: '200px', width: '200px' }}
        />
      </div>

      {!hideLegend && (
        <div className='flex flex-col gap-y-[8px] max-w-fit ml-[12px]'>
          {labels.map((label, index) => (
            <div key={index} className='break-words'>
              <Legend
                label={label}
                color={colors[index]}
                onClick={
                  label.startsWith(StringConstants.OTHERS)
                    ? () => setModalOpen(true)
                    : undefined
                }
                setModel={
                  label.startsWith(StringConstants.OTHERS)
                    ? () => setModalOpen(true)
                    : undefined
                }
              />
            </div>
          ))}
        </div>
      )}
      <DDSModal
        open={modalOpen}
        setOpen={setModalOpen}
        onIconClose={() => setModalOpen(false)}
        defaultFooter={false}
        className='overflow-hidden h-[50%]'
      >
        <div className='flex flex-col'>
          <div
            className='flex border-bottom items-start flex-row py-[16px] px-[24px]  border-b'
            style={{ borderBottomColor: pallete.colors.stroke2 }}
          >
            <DDSTypography.Title
              variant='bold'
              type='h3'
              className='text-center flex items-start'
            >
              Others
            </DDSTypography.Title>
          </div>
          <div className='flex flex-col px-[24px] py-[32px] overflow-y-scroll'>
            <div
              className='border rounded-md '
              style={{ borderColor: pallete.colors.stroke2 }}
            >
              <DDSTable columns={tableColumns} data={tableData} />
            </div>
          </div>
        </div>
      </DDSModal>
    </div>
  )
}

export default OverviewDoughnutChart
